/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import EditProfileNameModal from './EditProfileNameModal';
import AddEditProfileAddressModal from './AddEditProfileAddressModal';
import AddEditProfileEmailModal from './AddEditProfileEmailModal';
import AddEditProfilePhoneModal from './AddEditProfilePhoneModal';
import { copy } from './Common';
const UserProfilePersonalInforamtion = ({ data, pageLanguageContent, updateData }) => {
	const [showHideEditProfileNameModal, setShowHideEditProfileNameModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditAddressModal, setShowHideAddEditAddressModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditEmailModal, setShowHideAddEditEmailModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditPhoneModal, setShowHideAddEditPhoneModal] = useState(false); // eslint-disable-next-line
	const [countriesData, setCountriesData] = useState([]); // eslint-disable-next-line
	const [stateData, setStateData] = useState([]); // eslint-disable-next-line
	const [type, setType] = useState('');
	const [inputField, setInputField] = useState({
		first_name: data['first_name'],
		last_name: data['last_name'],
		second_last_name: '',
		middle_name: data['middle_name'],
		name_full: '',
		suffix_id: '',
		suffix: data['suffix'],
		email: '',
		phone: '',
		dial: '235',
		name_pref: data['name_pref'],
		facility_name: '',
		street_address_1: '',
		street_address_2: '',
		street_address_3: '',
		city: '',
		postal_code: '',
		country: '235',
		state: ''
	});

	const showHideModal = type => {
		const { first_name, last_name, middle_name, name_full, name_pref, suffix_id, second_last_name } = data;
		setInputField({
			...inputField,
			first_name,
			last_name,
			middle_name,
			name_pref,
			name_full,
			suffix_id,
			second_last_name
		});
		setType(type);
		if (type === 'edit_name') {
			setShowHideEditProfileNameModal(true);
		}
		if (type === 'add_address' || type === 'edit_address') {
			setShowHideAddEditAddressModal(true);
			getCountriesData();
		}

		if (type === 'add_email' || type === 'edit_email') {
			setShowHideAddEditEmailModal(true);
		}

		if (type === 'add_phone' || type === 'edit_phone') {
			setShowHideAddEditPhoneModal(true);
		}

		//setShowHideAddEditPhoneModal

		//setShowHideAddEditEmailModal
	};
	const closeAllModals = () => {
		setShowHideEditProfileNameModal(false);
		setShowHideAddEditAddressModal(false);
		setShowHideAddEditEmailModal(false);
		setShowHideAddEditPhoneModal(false);
	};

	const updateModal = (type, id, inputFields) => {
		updateData(id, inputFields);
	};

	/* function to get country data */
	function getCountriesData() {
		copiriPortalRequest('/canon/countries', config.app_version, 'get')
			.then(response => {
				setCountriesData(response.data);
			})
			.catch(error => {});
	}

	function copyContent(content) {
		copy(content);
	}

	return (
		<>
			<fieldset className='border p-2'>
				<legend className='w-auto'>{pageLanguageContent['PRL041'] ? pageLanguageContent['PRL041'] : ''}</legend>
				<Row>
					<Col lg={10}>
						<strong className='bold-text'>
							{pageLanguageContent['PRL083'] ? pageLanguageContent['PRL083'] : ''}:{' '}
						</strong>{' '}
						{data['name_full']}
					</Col>
					<Col lg={2} className='modalRightContent btn-badge'>
						{data['id'] && (
							<Badge
								color={'soft-info'}
								className=''
								onClick={() => showHideModal('edit_name')}
								data-toggle='tooltip'
								title='Edit'
							>
								<i className='far fa-edit profileEditContent'></i>
							</Badge>
						)}
					</Col>
				</Row>
				<Row className='mt-3  '>
					<Col lg={10}>
						<strong className='bold-text'>{pageLanguageContent['PRL084'] ? pageLanguageContent['PRL084'] : ''}</strong>
					</Col>
					{data['id'] && (!data['address'] || data['address'] === '') && (
						<Col lg={2} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='' onClick={() => showHideModal('add_address')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL085'] ? pageLanguageContent['PRL085'] : ''}
							</Badge>
						</Col>
					)}
				</Row>

				<Row>
					<Col lg={12} className='mb-1'>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['id'] && data['address'] && data['address'] !== '' ? (
						<>
							<Col lg={10} md='6' sm='12' className='mb-1'>
								<span>Po box 161174, Boiling so, S.C. 19316, United States Of America</span>
							</Col>
							<Col lg={2} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge color={'soft-info'} className='ml-2' onClick={() => showHideModal('edit_address')}>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>

				<br />
				<Row>
					<Col lg={10} className='mb-1'>
						<strong className='bold-text'>{pageLanguageContent['PRL087'] ? pageLanguageContent['PRL087'] : ''}</strong>
					</Col>
					{data['id'] && data['email'] && data['email'] === '' && (
						<Col lg={2} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='ml-2' onClick={() => showHideModal('add_email')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL088'] ? pageLanguageContent['PRL088'] : ''}
							</Badge>
						</Col>
					)}
				</Row>

				<Row>
					<Col lg={12} className='mb-1'>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['email'] && data['email'] !== '' ? (
						<>
							<Col lg={10} md='6' sm='12' className='mb-1'>
								<span>{data['email']}</span>

								{!data['email_validated'] && (
									<Badge color={'soft-danger'} className='mr-2' style={{ marginLeft: '10px' }}>
										<i className='fas fa-exclamation-triangle'></i> Not Verified
									</Badge>
								)}
							</Col>
							<Col lg={2} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge
									color={'soft-info'}
									className='mr-2 userProfileModalCopy'
									onClick={() => copyContent(data['email'])}
									data-toggle='tooltip'
									title='Copy'
								>
									<i className='far fa-copy'></i>
								</Badge>
								<Badge
									color={'soft-info'}
									className=''
									onClick={() => showHideModal('edit_email')}
									data-toggle='tooltip'
									title='Edit'
								>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>

				<br />

				<Row>
					<Col lg={10}>
						<strong className='bold-text'>{pageLanguageContent['PRL089'] ? pageLanguageContent['PRL089'] : ''}</strong>
					</Col>
					{data['id'] && (!data['phone'] || data['phone'] === '') && (
						<Col lg={2} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='ml-2' onClick={() => showHideModal('add_phone')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL090'] ? pageLanguageContent['PRL090'] : ''}
							</Badge>
						</Col>
					)}
				</Row>

				<Row>
					<Col lg={12}>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['phone'] && data['phone'] === '' ? (
						<>
							<Col lg={10} md='6' sm='12' className='mb-1'>
								<span>{data['phone']}</span>
							</Col>
							<Col lg={2} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge
									color={'soft-info'}
									className='ml-2'
									onClick={() => showHideModal('edit_phone')}
									data-toggle='tooltip'
									title='Edit'
								>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>
			</fieldset>

			<EditProfileNameModal
				showHide={showHideEditProfileNameModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				setInputField={setInputField}
				inputField={inputField}
				type={type}
				updateModal={updateModal}
			/>
			<AddEditProfileAddressModal
				showHide={showHideAddEditAddressModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				setInputField={setInputField}
				inputField={inputField}
				type={type}
				updateModal={updateModal}
			/>

			<AddEditProfileEmailModal
				showHide={showHideAddEditEmailModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				type={type}
				setInputField={setInputField}
				inputField={inputField}
				updateModal={updateModal}
			/>

			<AddEditProfilePhoneModal
				showHide={showHideAddEditPhoneModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				type={type}
				setInputField={setInputField}
				inputField={inputField}
				updateModal={updateModal}
			/>
		</>
	);
};
export default UserProfilePersonalInforamtion;
