import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { copiriPortalRequest, getLanguagePackUrl } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
const Error404 = () => {
	// eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(true);
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	useEffect(() => {
		getLanguagePack();
		// eslint-disable-next-line
	}, []);

	function getLanguagePack() {
		setShowHideLoader(true);
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData === null || checkData === undefined || checkData === '') {
			var getLanguageURL = getLanguagePackUrl();
			copiriPortalRequest(getLanguageURL, config.app_version, 'get')
				.then(response => {
					setShowHideLoader(false);
					if (response.data.result.ERR) {
						setPageLanguageContent(response.data.result.ERR);
						localStorage.setItem('idiom', JSON.stringify(response.data.result));
					}
				})
				.catch(error => {
					setShowHideLoader(false);
				});
		} else {
			if (checkData.ERR) {
				setPageLanguageContent(checkData.ERR);
			}
			setShowHideLoader(false);
		}
	}

	return (
		<Card className='text-center'>
			<CardBody className='p-5'>
				<div className='display-1 text-200 fs-error'>404</div>
				<p className='lead mt-4 text-800 text-sans-serif font-weight-semi-bold'>
					{pageLanguageContent['ERR001'] ? pageLanguageContent['ERR001'] : ''}
				</p>
				<hr />
				<p>
					{pageLanguageContent['ERR002'] ? pageLanguageContent['ERR002'] : ''}
					<a href='mailto:support@copiri.com' className='ml-1'>
						{pageLanguageContent['ERR003'] ? pageLanguageContent['ERR003'] : ''}
					</a>
					.
				</p>
				<Link className='btn btn-primary btn-sm mt-3' to='/'>
					<i className='fas fa-home-lg mr-2'></i>
					{pageLanguageContent['ERR004'] ? pageLanguageContent['ERR004'] : ''}
				</Link>
			</CardBody>
		</Card>
	);
};
export default Error404;
