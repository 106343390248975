/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
import { Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import config from '../../config/config';
// eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
const AddImageModal = props => {
	const [cropImageUrl, setCropImageUrl] = useState(null);
	const [image, setImage] = useState(null);
	const [logoTypeArr, setLogoTypeArr] = useState([]);
	const [type, setType] = useState('');
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	const [crop, setCrop] = useState({
		unit: '%', // default, can be 'px' or '%'
		x: 0,
		y: 0,
		width: 100,
		height: 100
	});
	const [imageType, setImageType] = useState('seal');
	const closeModal = () => {
		setImageType('seal');
		setCrop({ unit: '%', x: 0, y: 0, width: 100, height: 100 });
	};

	useEffect(() => {
		if (props.showHide) {
			setType(props.type);
			getLanguagePack();
			setCropImageUrl(props.cropImageUrl);
			setImageType('seal');
			setCrop({ unit: '%', x: 0, y: 0, width: 100, height: 100 });
		}
		// eslint-disable-next-line
	}, [props]);

	/* API to get the Language translation data */
	function getLanguagePack() {
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData === null || checkData === undefined || checkData === '') {
			copiriPortalRequest('/Languages/177394503/package/PRT/CLD', config.app_version, 'get')
				.then(response => {
					if (checkData['CLD']) {
						setPageLanguageContent(response.data.result.CLD);
					}
					var json = response.data.result.CLD['CLD067'];
					json = JSON.parse(json.replace(/'/g, '"'));
					setLogoTypeArr(json);
				})
				.catch(error => {});
		} else {
			if (checkData['CLD']) {
				setPageLanguageContent(checkData['CLD']);
			}
			var json = checkData.CLD['CLD067'];
			json = JSON.parse(json.replace(/'/g, '"'));
			setLogoTypeArr(json);
		}
	}

	/* function to change image type */
	const getImageType = event => {
		setImageType(event.target.value);
	};

	/* function to get cropped image and upload new image */

	function getCroppedImg() {
		//document.getElementById("croppedImageModal").style.opacity = '0.2';
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);
		const base64Image = canvas.toDataURL('image/jpeg');
		canvas.toBlob(blob => {
			var file2 = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
			uploadSlideImage(base64Image, file2);
		}, 'image/jpeg');
		props.closeModal();
		//setShowModal(!showModal);
		closeModal();
	}

	/* function to request upload new image*/
	function uploadSlideImage(imageData, inputFile) {
		if (type === 'logo') {
			props.logoImageCallback(imageData, imageType, inputFile);
		} else {
			props.callback(imageData, imageType, inputFile);
		}
	}

	return (
		<div>
			<Modal isOpen={props.showHide} toggle={props.closeModal} id='historyModal' size='lg' className='addImg'>
				<ModalHeader toggle={props.closeModal}>
					{pageLanguageContent['CLD063'] ? pageLanguageContent['CLD063'] : ''}{' '}
					{pageLanguageContent['CLD111'] ? pageLanguageContent['CLD111'] : ''}
				</ModalHeader>
				<ModalBody>
					<ReactCrop src={cropImageUrl} onImageLoaded={setImage} crop={crop} onChange={setCrop} keepSelection={true} />
					<p></p>
					<Row>
						<Col md={8} className='addimg-selectbox'>
							<span className='mr-2'>{pageLanguageContent['CLD130'] ? pageLanguageContent['CLD130'] : ''}:</span>
							<select className='form-control' value={imageType} onChange={getImageType} id='selectCountry'>
								{logoTypeArr &&
									logoTypeArr.length > 0 &&
									logoTypeArr.map((item, i) => (
										<option value={item.value} key={item.value}>
											{item.label}
										</option>
									))}
							</select>
						</Col>
						<Col md={4} className='text-right'>
							<Button color='primary' onClick={getCroppedImg}>
								{pageLanguageContent['CLD043'] ? pageLanguageContent['CLD043'] : ''}
							</Button>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default AddImageModal;
