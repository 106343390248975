// eslint-disable-next-line
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import Stripe from '../components/stripe';// eslint-disable-next-line
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils'; // eslint-disable-next-line
import config from '../components/config/config'; // eslint-disable-next-line
import Loader from '../components/common/Loader'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import PaymentSuccess from '../components/stripe/PaymentSuccess';
const PaymentLayout = ({ location }) => {
	// eslint-disable-next-line
	const { isFluid, isVertical, navbarStyle } = useContext(AppContext); // eslint-disable-next-line
	const isKanban = getPageName('kanban');
	useEffect(() => {
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	return (
		<div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
			<div className='content'>
				<Switch>
					<Route path='/' exact component={Stripe} />
					<Route path='/success/payment' exact component={PaymentSuccess} />
					{/*Redirect*/}
					<Redirect to='/' />
				</Switch>
			</div>
		</div>
	);
};

PaymentLayout.propTypes = { location: PropTypes.object.isRequired };

export default PaymentLayout;
