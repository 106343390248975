export const version = '3.0.0';
export const appVersion = '3.0.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
	isFluid: true,
	isRTL: false,
	isDark: false,
	isTopNav: false,
	isVertical: true,
	get isCombo() {
		return this.isVertical && this.isTopNav;
	},
	showBurgerMenu: false, // controls showing vertical nav on mobile
	currency: '$',
	isNavbarVerticalCollapsed: false,
	navbarStyle: 'card'
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
