/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const TwitterField = props => {
	// eslint-disable-next-line
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props; // eslint-disable-next-line
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		twitter: ''
	});
	const [validation, setValidation] = useState({
		error_twitter: ''
	});

	useEffect(() => {
		if (props.id) {
			const { twitter } = lodgeData.social;
			setInputField({ ...inputField, twitter });
		}
		// eslint-disable-next-line
	}, [props.id]);

	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};
	function updateData() {
		let errors = { ...validation };
		var errorCount = 0;

		if (!inputField.twitter.trim()) {
			errors.error_twitter = pageLanguageContent['CLD071'];
			errorCount++;
		} else {
			errors.error_twitter = '';
		}

		var sendFormData = {};
		var sendData = {};
		sendFormData['string'] = inputField['twitter'];
		sendData['value'] = sendFormData;
		sendData['type'] = 'Twitter';

		setValidation(errors);
		if (errorCount <= 0) {
			setShowLoader(true);
			copiriPortalRequest('/organizations/' + id + '/additional-info', config.app_version, 'patch', sendData)
				.then(response => {
					setShowLoader(false);
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success(pageLanguageContent['CLD119']);
						closeModal();
						updateLodgeModal('social', inputField['twitter'], 'twitter', '');
					}
				})
				.catch(error => {
					toast.error(error.response.data.result);
					setShowLoader(false);
				});
		}
	}
	return (
		<Row>
			<Col lg={12}>
				<FormGroupInput
					id='twitter'
					label={pageLanguageContent['CLD039'] ? pageLanguageContent['CLD039'] : ''}
					placeholder=''
					type='text'
					name='twitter'
					onChange={inputsHandler}
					value={inputField.twitter}
				/>
				{validation.error_twitter && <p className='formValidationError'>{validation.error_twitter}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default TwitterField;
