import React, { useContext } from 'react';
import { Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import classNames from 'classnames';
import { navbarBreakPoint } from '../../config';

const TopNavRightSideNavItem = props => {
	const { isTopNav, isCombo } = useContext(AppContext);
	return (
		<Nav navbar className='navbar-nav-icons ml-auto flex-row align-items-center heelo'>
			{(isCombo || isTopNav) && (
				<NavItem className={classNames(`p-2 px-lg-0 cursor-pointer`, { [`d-${navbarBreakPoint}-none`]: isCombo })}>
					<NavLink tag={Link} to='/changelog' id='changelog'>
						<i className='fad fa-code-branch' transform='right-6 grow-4'></i>
					</NavLink>
					<UncontrolledTooltip autohide={false} placement='left' target='changelog'>
						Changelog
					</UncontrolledTooltip>
				</NavItem>
			)}
			<ProfileDropdown profileDropDownData={props.profileDropDownData} langContetn={props.langContetn} />
		</Nav>
	);
};

export default TopNavRightSideNavItem;
