/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
// eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup } from 'reactstrap';
// eslint-disable-next-line
import img_2 from '../../../assets/img/lodges/img_02.jpg'; // eslint-disable-next-line
import img_4 from '../../../assets/img/lodges/img_04.jpg'; // eslint-disable-next-line
import img_5 from '../../../assets/img/lodges/img_5.png'; // eslint-disable-next-line
import AddImageModal from './AddImageModal';
import image_loader from '../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader';
const LodgeManageImagesModal = props => {
	// eslint-disable-next-line
	const { lodgeData, lodgeImages, pageLanguageContent, updateLodgeModal } = props;
	const [cropImageUrl, setCropImageUrl] = useState(null); // eslint-disable-next-line
	const [sealImage, setSealImage] = useState('');
	const [logoTypeArr, setLogoTypeArr] = useState([]);
	const [formValues, setFormValues] = useState([{ Seal: '', type: 'seal', inputFile: '' }]);
	const [showLoader, setShowLoader] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [type, setType] = useState('add_images');
	const [showHideLoader, setShowHideLoader] = useState(false); // eslint-disable-next-line
	useEffect(() => {
		if (props.showHide) {
			var json = pageLanguageContent['CLD067'];
			json = JSON.parse(json.replace(/'/g, '"'));
			setLogoTypeArr(json);
			getOrgImage(lodgeData['id']);
		}
		// eslint-disable-next-line
	}, [props]);

	/* function to get the organization images */
	function getOrgImage(id) {
		setShowHideLoader(true);
		copiriPortalRequest('/organizations/' + id + '/images', config.app_version, 'get')
			.then(response => {
				setShowHideLoader(false);
				if (response.data.carousel.length > 0) {
					checkImages(response.data.carousel);
				}
			})
			.catch(error => {
				setShowHideLoader(false);
			});
	}

	/* function to check organization images*/
	function checkImages(lodgeImages) {
		// eslint-disable-next-line
		var newImages = [];
		if (lodgeImages.length > 0) {
			for (var i = 0; i < lodgeImages.length; i++) {
				var keys = Object.keys(lodgeImages[i]);
				var imageType = 'seal';
				if (keys['0'] && keys['0'] !== undefined) {
					imageType = keys['0'].toLowerCase();
				}
				var file2 = new File([lodgeImages[i]['Seal']], 'fileName.jpg', { type: 'image/jpeg' });
				newImages.push({
					Seal: lodgeImages[i]['Seal'],
					type: imageType,
					inputFile: file2
				});
			}
			setFormValues(newImages);
		}
	}

	/* function to trigger input file */
	function clickInputFile() {
		document.getElementById('uploadCaptureInputFile').click();
	}

	/* function to get image url for cropping and show modal */
	const getImageUrl2 = event => {
		setType('add_images');
		setShowModal(!showModal);
		setCropImageUrl(URL.createObjectURL(event.target.files[0]));
	};
	function callback(image, type = '', inputFile) {
		setSealImage(image);
		setFormValues([...formValues, { Seal: image, type: type, inputFile: inputFile }]);
	}

	/* function to remove image fields */
	let removeFormFields = index => {
		if (formValues.length > 1) {
			let newFormValues = [...formValues];
			newFormValues.splice(index, 1);
			setFormValues(newFormValues);
		}
	};

	/* function to close modal */
	const closeModal = () => {
		setShowModal(!showModal);
	};

	/* function to get the imput values */
	let handleChange = (i, e) => {
		let newFormValues = [...formValues];
		newFormValues[i][e.target.name] = e.target.value;
		setFormValues(newFormValues);
	};

	/* function to update the organization images */
	function updateData() {
		if (formValues.length > 0) {
			for (var j = 0; j < formValues.length; j++) {
				setShowLoader(true);
				const formdata = new FormData();
				formdata.append('source', 'organization');
				formdata.append('id', lodgeData['id']);
				formdata.append('type', formValues[j]['type']);
				formdata.append('userimage', formValues[j]['inputFile']);
				copiriPortalRequest('/images', config.app_version, 'post', formdata)
					.then(response => {
						setShowLoader(false);
						if (response.data.status === 'SUCCESS') {
							props.closeModal();
						}
					})
					.catch(error => {
						setShowLoader(false);
					});
			}
		}
	}

	return (
		<div>
			<Modal isOpen={props.showHide} toggle={props.closeModal} id='historyModal' size='lg' className='Manage-images'>
				<ModalHeader toggle={props.closeModal}>
					{pageLanguageContent['CLD143'] ? pageLanguageContent['CLD143'] : ''}
				</ModalHeader>
				<ModalBody>
					{showHideLoader ? (
						<CopiriLoader />
					) : (
						<ul>
							{formValues &&
								formValues.length > 0 &&
								formValues.map((item, index) => (
									<li key={index}>
										<Row className='row-100'>
											<Col md={1} className='mng-point-btn'>
												<Badge color={'soft-info'} className='pointer-icon'>
													<i className='fal fa-hand-pointer'></i>
												</Badge>
											</Col>
											<Col md={3} className='mng-image-box'>
												<div className='image-area'>
													<div className='img-box'>
														<img src={item.Seal} alt={item.type} />
													</div>
													<Badge color={'soft-info'} className='imgDel-icon' onClick={() => removeFormFields(index)}>
														<i className='fas fa-trash-alt'></i>
													</Badge>
												</div>
											</Col>
											<Col md={8} className='mng-content-box'>
												<div className='outer-content-box'>
													<Row className='align-items-center mb-3'>
														<div className='radio-btn-left'>
															<input type='radio' id='radioM' name={'btnradio-'} className='mr-1' />{' '}
															<label htmlFor='radioM' className='m-0'>
																{pageLanguageContent['CLD144'] ? pageLanguageContent['CLD144'] : ''}
															</label>
														</div>
														<div className='radio-btn-right'>
															<label className='mr-2'>
																{pageLanguageContent['CLD145'] ? pageLanguageContent['CLD145'] : ''}:
															</label>
															<FormGroup className='m-0'>
																<select
																	className='form-control'
																	id='selectCountry'
																	name='type'
																	onChange={e => handleChange(index, e)}
																	defaultValue={item.type}
																>
																	{logoTypeArr &&
																		logoTypeArr.length > 0 &&
																		logoTypeArr.map((lodges, i) => (
																			<option value={lodges.value} key={lodges.value}>
																				{lodges.label}
																			</option>
																		))}
																</select>
															</FormGroup>
														</div>
													</Row>
													<Row className='lblue-box'>
														<Col md={12}>
															<div className='d-flex'>
																<h6 className='mr-2'>
																	{pageLanguageContent['CLD146'] ? pageLanguageContent['CLD146'] : ''}:
																</h6>
																<p>Xyx User</p>
															</div>
														</Col>
														<Col md={6} className='border-right-lblue'>
															<div className='d-flex'>
																<h6 className='mr-2'>
																	{pageLanguageContent['CLD147'] ? pageLanguageContent['CLD147'] : ''}:
																</h6>
																<p> 01 Nov. 2022 at 3:15pm</p>
															</div>
														</Col>
														<Col md={6}>
															<div className='d-flex'>
																<h6 className='mr-2'>
																	{pageLanguageContent['CLD148'] ? pageLanguageContent['CLD148'] : ''}:
																</h6>
																<p> 01 Nov. 2022 at 3:16pm</p>
															</div>
														</Col>
													</Row>
												</div>
											</Col>
										</Row>
									</li>
								))}

							<li>
								<Row className='row-100'>
									<Col md={1} className='mng-point-btn'>
										{/* <Badge color={'soft-info'}  className="pointer-icon">
                    <i className="fal fa-hand-pointer"></i>
                  </Badge>*/}
									</Col>
									<Col md={3} className='mng-image-box'>
										<div className='image-area' onClick={() => clickInputFile()}>
											<Badge color={'soft-info'} className='plus-icon'>
												<i className='far fa-plus'></i>
											</Badge>
											<Input
												type='file'
												onChange={getImageUrl2}
												id='uploadCaptureInputFile'
												accept='.jpg, .jpeg, .png'
												hidden
											/>
											{/* <img src={img_5} alt="img1"/>
                    <Badge color={'soft-info'}  className="imgDel-icon">
                      <i className="fas fa-trash-alt"></i>
                    </Badge>*/}
										</div>
									</Col>
								</Row>
							</li>
						</ul>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateData}>
						{showLoader ? (
							<img src={image_loader} alt='loader' height='20' width='20' />
						) : pageLanguageContent['CLD149'] ? (
							pageLanguageContent['CLD149']
						) : (
							''
						)}
					</Button>
				</ModalFooter>
			</Modal>

			<AddImageModal
				showHide={showModal}
				closeModal={closeModal}
				callback={callback}
				cropImageUrl={cropImageUrl}
				pageLanguageContent={props.pageLanguageContent}
				type={type}
			/>
		</div>
	);
};
export default LodgeManageImagesModal;
