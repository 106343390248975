/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import LodgeHistoryModal from './LodgeHistoryModal'; // eslint-disable-next-line
import LodgeAddressModal from './LodgeAddressModal'; // eslint-disable-next-line
import LodgeContentModal from './LodgeContentModal'; // eslint-disable-next-line
const LodgeContactSection = props => {
	// eslint-disable-next-line
	const { lodgeData, pageLanguageContent, updateLodgeModal } = props;
	const [showHideHistoryModal, setShowHideHistoryModal] = useState(false);
	const [showHideContentModal, setShowHideContentModal] = useState(false);
	const [contentType, setContentType] = useState('');
	const [headerLabel, setHeaderLabel] = useState('');
	const [historyText, setHistoryText] = useState('');
	const [id, setId] = useState('');
	useEffect(() => {
		if (lodgeData['id']) {
		}
		// eslint-disable-next-line
	}, [lodgeData['id']]);

	/* show lodge history modal */
	const openLodgeModal = text => {
		setHistoryText(text);
		setShowHideHistoryModal(true);
	};
	/* close lodge history modal */
	const closeHistoryModal = () => {
		setShowHideHistoryModal(false);
	};
	/* open lodge content modal */
	const openLodgeContentModal = (text, type) => {
		setId(lodgeData['id']);
		setHeaderLabel(text);
		setContentType(type);
		setShowHideContentModal(true);
	};
	/* close lodge content modal */
	const closeLodgeContentModal = () => {
		setShowHideContentModal(false);
	};
	return (
		<>
			<Row className='lodge-social-links border-bottom'>
				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD034'] ? pageLanguageContent['CLD034'] : ''}:</h6>
							<span>
								{lodgeData['website'] ? (
									<a href={lodgeData['website']} target='_blank' rel='noopener noreferrer'>
										{lodgeData['website'].slice(0, 20)}...{' '}
									</a>
								) : (
									<>{pageLanguageContent['CLD040'] ? pageLanguageContent['CLD040'] : ''}</>
								)}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(pageLanguageContent['CLD139'] ? pageLanguageContent['CLD139'] : '', 'website')
									}
								></i>
							</Badge>
							{lodgeData['website'] ? (
								<Badge color={'soft-info'} className='mr-1' onClick={() => openLodgeModal('website')}>
									<i className='fas fa-eye'></i>
								</Badge>
							) : (
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							)}
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD035'] ? pageLanguageContent['CLD035'] : ''}:</h6>
							<span>
								{lodgeData['email']
									? lodgeData['email']
									: pageLanguageContent['CLD040']
									? pageLanguageContent['CLD040']
									: ''}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(pageLanguageContent['CLD140'] ? pageLanguageContent['CLD140'] : '', 'email')
									}
								></i>
							</Badge>
							{lodgeData['email'] ? (
								<Badge color={'soft-info'} className='mr-1' onClick={() => openLodgeModal('email')}>
									<i className='fas fa-eye'></i>
								</Badge>
							) : (
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							)}
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD036'] ? pageLanguageContent['CLD036'] : ''}:</h6>
							<span>
								{lodgeData['phone']
									? lodgeData['phone']
									: pageLanguageContent['CLD040']
									? pageLanguageContent['CLD040']
									: ''}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(pageLanguageContent['CLD141'] ? pageLanguageContent['CLD141'] : '', 'phone')
									}
								></i>
							</Badge>
							{lodgeData['phone'] ? (
								<Badge color={'soft-info'} className='mr-1' onClick={() => openLodgeModal('phone')}>
									<i className='fas fa-eye'></i>
								</Badge>
							) : (
								<Badge color={'soft-secondary'} className='mr-1'>
									<i className='fas fa-eye'></i>
								</Badge>
							)}
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD037'] ? pageLanguageContent['CLD037'] : ''}:</h6>
							<span>
								{lodgeData['social'] && (
									<>
										{lodgeData['social']['facebook_link'] ? (
											<>{lodgeData['social']['facebook']}</>
										) : (
											<>{pageLanguageContent['CLD040'] ? pageLanguageContent['CLD040'] : ''}</>
										)}
									</>
								)}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(
											pageLanguageContent['CLD142'] ? pageLanguageContent['CLD142'] : '',
											'facebook'
										)
									}
								></i>
							</Badge>
							{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('facebook')}>
                        <i className="fas fa-eye"></i>
                      </Badge>*/}
							<Badge color={'soft-secondary'} className='mr-1'>
								<i className='fas fa-eye'></i>
							</Badge>
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD038'] ? pageLanguageContent['CLD038'] : ''}:</h6>
							<span>
								{lodgeData['social'] && (
									<>
										{lodgeData['social']['instagram_link'] ? (
											<>{lodgeData['social']['instagram']}</>
										) : (
											<>{pageLanguageContent['CLD040'] ? pageLanguageContent['CLD040'] : ''}</>
										)}
									</>
								)}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge
								color={'soft-info'}
								className='mr-1'
								onClick={() =>
									openLodgeContentModal(pageLanguageContent['CLD142'] ? pageLanguageContent['CLD142'] : '', 'instagram')
								}
							>
								<i className='far fa-edit'></i>
							</Badge>
							{/* <Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('instagram')}>
                          <i className="fas fa-eye"></i>
                        </Badge>*/}
							<Badge color={'soft-secondary'} className='mr-1'>
								<i className='fas fa-eye'></i>
							</Badge>
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD039'] ? pageLanguageContent['CLD039'] : ''}:</h6>
							<span>
								{lodgeData['social'] && (
									<>
										{lodgeData['social']['twitter_link'] ? (
											<>{lodgeData['social']['twitter']}</>
										) : (
											<>{pageLanguageContent['CLD040'] ? pageLanguageContent['CLD040'] : ''}</>
										)}
									</>
								)}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(pageLanguageContent['CLD142'] ? pageLanguageContent['CLD142'] : '', 'twitter')
									}
								></i>
							</Badge>
							{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('twitter')}>
                        <i className="fas fa-eye"></i>
                      </Badge>*/}
							<Badge color={'soft-secondary'} className='mr-1'>
								<i className='fas fa-eye'></i>
							</Badge>
						</div>
					</div>
				</Col>

				<Col lg={4}>
					<div className='d-flex lodge-social-item'>
						<div className='d-flex lodge-social-content'>
							<h6>{pageLanguageContent['CLD152'] ? pageLanguageContent['CLD152'] : ''}:</h6>
							<span>
								{lodgeData['social'] && (
									<>
										{lodgeData['social']['linkedin_link'] ? (
											<>{lodgeData['social']['linkedin']}</>
										) : (
											<>{pageLanguageContent['CLD040'] ? pageLanguageContent['CLD040'] : ''}</>
										)}
									</>
								)}
							</span>
						</div>
						<div className='ml-auto btns-ladge'>
							<Badge color={'soft-info'} className='mr-1'>
								<i
									className='far fa-edit'
									onClick={() =>
										openLodgeContentModal(
											pageLanguageContent['CLD142'] ? pageLanguageContent['CLD142'] : '',
											'linkedin'
										)
									}
								></i>
							</Badge>
							{/*<Badge color={'soft-info'}  className="mr-1" onClick={() => openLodgeModal('twitter')}>
                        <i className="fas fa-eye"></i>
                      </Badge>*/}
							<Badge color={'soft-secondary'} className='mr-1'>
								<i className='fas fa-eye'></i>
							</Badge>
						</div>
					</div>
				</Col>
			</Row>
			<LodgeHistoryModal
				lodgeData={lodgeData}
				showHideHistoryModal={showHideHistoryModal}
				closeHistoryModal={closeHistoryModal}
				updateLodgeModal={updateLodgeModal}
				historyText={historyText}
			/>

			<LodgeContentModal
				showHide={showHideContentModal}
				closeModal={closeLodgeContentModal}
				id={id}
				headerLabel={headerLabel}
				contentType={contentType}
				lodgeData={lodgeData}
				updateLodgeModal={updateLodgeModal}
			/>
		</>
	);
};
export default LodgeContactSection;
