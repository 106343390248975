/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import Avatar from './Avatar';
import team2 from '../../assets/img/team/avatar.svg';
import UserProfileCardSection from './UserProfileCardSection';
import Badge from 'reactstrap/es/Badge';
import { copy } from './Common';
const UserProfileTopSection = ({ data, pageLanguageContent, showCardSection }) => {
	/* function to copy content*/
	function copyContent(content) {
		copy(content);
	}
	return (
		<Row>
			<Col lg={3} className='text-center'>
				<Avatar
					src={team2}
					width={200}
					size='5xl'
					rounded='circle'
					className=' avatar-profile1'
					mediaClass='img-thumbnail shadow-sm'
				/>
			</Col>
			<Col lg={9}>
				<p className='modal-user-name'>{data['name_full'] ? data['name_full'] : pageLanguageContent['PRL033']}</p>
				{ data['birthdate'] && 
					<h5 className='font-weight-semi-bold fs--2'>
						<span className="badge badge-success dataOverViewTab" style={{marginLeft:'0px'}}><i className="fas fa-seedling"></i></span>
						<span style={{marginLeft:'10px'}}> {data['birthdate'] ? data['birthdate'] : ''}</span>
					</h5>
				}
				<div className='modal-user-title'>
					<span className='title-text'>
						{pageLanguageContent['PRL077'] ? pageLanguageContent['PRL077'] : ''} :{' '}
						{pageLanguageContent['PRL078'] ? pageLanguageContent['PRL078'] : ''}
					</span>
					<div>
						<span className='title-text'>
							{' '}
							{pageLanguageContent['PRL079'] ? pageLanguageContent['PRL079'] : ''} : {data['id']}
						</span>
						<Badge
							color={'soft-info'}
							className='ml-2 user-copy-icon'
							onClick={() => copyContent(data['id'])}
							data-toggle='tooltip'
							title='Copy'
						>
							<i className='far fa-copy'></i>
						</Badge>
					</div>
				</div>
				<UserProfileCardSection data={data} pageLanguageContent={pageLanguageContent} />
			</Col>
		</Row>
	);
};
export default UserProfileTopSection;
