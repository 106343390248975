// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
import AccordionItem from './AccordionItem'; // eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'; // eslint-disable-next-line
const Accordion = ({ cardData, pageLanguageContent, showData, pageData, profileId }) => {
	const [clicked, setClicked] = useState(0);
	useEffect(() => {}, [showData]);

	const handleToggle = index => {
		if (clicked === index) {
			return setClicked('0');
		}
		setClicked(index);
	};

	return (
		<>
			{cardData &&
				cardData.length > 0 &&
				cardData.map((item, index) => (
					<AccordionItem
						onToggle={() => handleToggle(index)}
						active={clicked === index}
						key={index}
						data={item}
						currentIndex={index}
						profileId={profileId}
						pageLanguageContent={pageLanguageContent}
					/>
				))}
		</>
	);
};

export default Accordion;
