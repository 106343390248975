import React from 'react'; // eslint-disable-next-line
import { Row, Col, Card, CardBody, Button, NavLink } from 'reactstrap'; // eslint-disable-next-line
import Logo from '../navbar/BankingPaymentLogo'; // eslint-disable-next-line
import './style.css'; // eslint-disable-next-line
import { toast } from 'react-toastify';
const HeaderSection = props => {
	const { configVariableData } = props;
	return (
		<>
			<div className='row'>
				<div style={{ textAlign: 'center', marginTop: '0.625rem' }} className='col-12 loginLogo col'>
					<Logo
						at='navbar-top'
						width={40}
						id='topLogo'
						siteConfig={configVariableData}
						style={{ marginLeft: '0.938rem' }}
					/>
				</div>
			</div>
			{configVariableData && configVariableData?.alert?.show && (
				<div className='stripe_payment_top_bar' style={{ backgroundColor: configVariableData.alert.color }}>
					<Row>
						<Col lg={12}>
							<div>{configVariableData.alert.text}</div>
						</Col>
					</Row>
				</div>
			)}
		</>
	);
};
export default HeaderSection;
