import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BankingPaymentLogo = ({ at, width, className,siteConfig, ...rest }) => {
	return (
			<div className="navbar-brand text-left payment-navbar-top">
				<div
					className={classNames(
						'd-flex',
						{
							'align-items-center py-3': at === 'navbar-vertical',
							'align-items-center': at === 'navbar-top',
							'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
						},
						className
					)}
				>{
					siteConfig?.header?.seal ?
					<>
						{ 
							siteConfig?.header?.seal_url ?
							<a href={siteConfig.header.seal_url} target="_blank" rel="noopener noreferrer" style={{cursor:'pointer'}}><img className='' src={siteConfig.header.seal} alt={siteConfig.header.seal_alt} width='100px' /></a>
							:
							<img className='' src={siteConfig.header.seal} alt={siteConfig.header.seal_alt} width='100px' />
						}
					</>
					:
					<img className='' src={''} alt="" width='100px' />
				}
				</div>
			</div>
	);
};

BankingPaymentLogo.propTypes = {
	at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
	width: PropTypes.number,
	className: PropTypes.string
};

BankingPaymentLogo.defaultProps = { at: 'auth', width: 58 };

export default BankingPaymentLogo;
