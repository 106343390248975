// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
import WalletPassAccordionItem from './WalletPassAccordionItem'; // eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
const faqs = [
	{
		question: 'Wallet Pass',
		answer: 'Content will added Here'
	}
];

const WalletPassAccordion = props => {
	const { pageLanguageContent,profileId } = props;
	const [clicked, setClicked] = useState('0');
	const handleToggle = index => {
		if (clicked === index) {
			return setClicked('0');
		}

		setClicked(index);
	};

	return (
		<>
			{faqs.map((faq, index) => (
				<WalletPassAccordionItem
					onToggle={() => handleToggle(index)}
					active={clicked === index}
					key={index}
					faq={faq}
					currentIndex={index}
					pageLanguageContent={pageLanguageContent}
					profileId={profileId}
				/>
			))}
		</>
	);
};

export default WalletPassAccordion;
