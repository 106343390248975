/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Button, Card, CardBody, Label, Input, Form, FormGroup } from 'reactstrap';
const CookieBanner = props => {
	var checkCookie = check_cookie_name('CopiriCookies');
	var CopiriCookiesPrefs = check_cookie_name('CopiriCookiesPrefs');
	if (checkCookie && CopiriCookiesPrefs) {
		checkCookie = true;
	} else {
		checkCookie = false;
	}
	//defining initial states
	const [banner, setBanner] = useState(checkCookie ? true : false);
	const [button, setButton] = useState('Accept All');
	const [preferences, setToggled] = useState(false);
	var [firstCheckbox, setFirstCheckbox] = useState(false);
	var [secondCheckbox, setSecondCheckbox] = useState(false);
	var [thirdCheckbox, setThirdCheckbox] = useState(false);
	// accept cookies
	const acceptCookie = e => {
		var cname = 'CopiriCookies';
		var cvalue = true;
		var d = new Date();
		d.setTime(d.getTime() + 3600 * 1000 * 24 * 365 * 1);
		var expires = '; expires=' + d.toUTCString();
		document.cookie = cname + '=' + (cvalue || '') + expires + '; path=/';
		var cName2 = 'CopiriCookiesPrefs';
		if (firstCheckbox || secondCheckbox || thirdCheckbox) {
			var prefArr = [];
			if (firstCheckbox) {
				prefArr.push('Preferences');
			}
			if (secondCheckbox) {
				prefArr.push('Analytics');
			}
			if (thirdCheckbox) {
				prefArr.push('Marketing');
			}
			document.cookie = cName2 + '=' + (prefArr || '') + expires + '; path=/';
		} else {
			var prefValue = ['preferences', 'analytics', 'marketing'];
			document.cookie = cName2 + '=' + (prefValue || '') + expires + '; path=/';
		}
		setBanner(banner => !banner);
	};

	function check_cookie_name(name) {
		var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
		if (match) {
			return true;
		} else {
			return false;
		}
	}

	//customPreferences
	const customPreferences = e => {
		setToggled(preferences => !preferences);
		if (preferences) {
			setButton('Accept All');
		} else {
			setButton('Save');
		}
		if (firstCheckbox) {
			setFirstCheckbox(!firstCheckbox);
		}
		if (secondCheckbox) {
			setSecondCheckbox(!secondCheckbox);
		}
		if (thirdCheckbox) {
			setThirdCheckbox(!thirdCheckbox);
		}
	};

	return (
		<div id='cookieBanner' className='cookieBanner'>
			{!banner ? (
				<Card
					className='overflow-hidden cookieBanner'
					style={{
						minWidth: '12rem',
						backgroundColor: '#464646',
						position: 'fixed',
						right: '30px',
						maxWidth: '375px',
						padding: '20px',
						borderRadius: '5px',
						boxShadow: '0 6px 6px rgba(0,0,0,0.25)',
						marginLeft: '30px',
						zIndex: '1000',
						bottom: '65px'
					}}
				>
					<CardBody className='p-0'>
						<h6 style={{ color: '#969696', fontSize: '18px' }}>
							<span role='img' aria-label='circle'>
								🍪
							</span>{' '}
							Cookies Preferences
						</h6>
						<p style={{ color: '#ffffff' }} className='fs--1'>
							Copiri uses cookies to give you the best experience on our sites. More information is available in our{' '}
							<a
								href='https://amity.copiri.com/terms.html#cookies'
								target='_blank'
								rel='noopener noreferrer'
								style={{ color: '#969696' }}
							>
								Cookies Policy.
							</a>
						</p>

						{preferences ? (
							<Form>
								<h6 style={{ color: '#969696', fontSize: '18px' }}>Select Cookies to Accept:</h6>
								<FormGroup check inline>
									<Label className='text-light'>
										<Input type='checkbox' disabled checked /> Necessary
									</Label>
								</FormGroup>
								<FormGroup check inline>
									<Label className='text-light'>
										<Input
											type='checkbox'
											value='preferences'
											onChange={() => setFirstCheckbox(!firstCheckbox)}
											checked={firstCheckbox}
										/>{' '}
										Preferences
									</Label>
								</FormGroup>
								<FormGroup check inline>
									<Label className='text-light'>
										<Input
											type='checkbox'
											value='analytics'
											onChange={() => setSecondCheckbox(!secondCheckbox)}
											checked={secondCheckbox}
										/>{' '}
										Analytics
									</Label>
								</FormGroup>
								<FormGroup check inline>
									<Label className='text-light'>
										<Input
											type='checkbox'
											value='marketing'
											onChange={() => setThirdCheckbox(!thirdCheckbox)}
											checked={thirdCheckbox}
										/>{' '}
										Marketing
									</Label>
								</FormGroup>
							</Form>
						) : (
							''
						)}
						<Button color='secondary' size='sm' className='mr-2 mb-2' onClick={acceptCookie}>
							{button}
						</Button>
						{!preferences ? (
							<Button color='falcon-default' size='sm' className='mr-2 mb-2' onClick={customPreferences}>
								Customize
							</Button>
						) : (
							''
						)}
					</CardBody>
				</Card>
			) : (
				''
			)}
		</div>
	);
};
export default CookieBanner;
