import React, { Fragment, useState, useEffect } from 'react'; // eslint-disable-next-line
import { Row, Col, Card, CardBody } from 'reactstrap'; // eslint-disable-next-line
import Notifications from './Notifications';
import AddNewMeeting from './AddNewMeeting';
import UpcomingEvents from './UpcomingEvents';
import PostMessageForm from './PostMessageForm';
import { copiriPortalRequest, getLanguagePackUrl } from '../api/api';
import config from '../../config';
import CopiriLoader from '../common/CopiriLoader';
import './style.css'; // eslint-disable-next-line
import RecentPayments from './RecentPayments';
const Dashboard = () => {
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	const [widgetLanguageContent, setWidgetLanguageContent] = useState([]);
	const [paymentLanguageContent, setPaymentLanguageContent] = useState([]);
	const [showHideLoader, setShowHideLoader] = useState(false);
	const [updateAPI, setUpdateAPI] = useState(false);
	useEffect(() => {
		/* get Language packe data */
		getLanguagePack();
		// eslint-disable-next-line
	}, []);

	function getLanguagePack() {
		setShowHideLoader(true);
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData === null || checkData === undefined || checkData === '') {
			var getLanguageURL = getLanguagePackUrl();
			copiriPortalRequest(getLanguageURL, config.app_version, 'get')
				.then(response => {
					setShowHideLoader(false);
					if (response.data.result.HME) {
						setPageLanguageContent(response.data.result.HME);
					}
					setWidgetLanguageContent(response?.data?.result?.WMT);
					setPaymentLanguageContent(response?.data?.result?.PMT);
					localStorage.setItem('idiom', JSON.stringify(response.data.result));
				})
				.catch(error => {
					setShowHideLoader(false);
				});
		} else {
			setShowHideLoader(false);
			if (checkData.HME) {
				setPageLanguageContent(checkData.HME);
			}
			if (checkData.WMT) {
				setWidgetLanguageContent(checkData.WMT);
			}
			if (checkData.PMT) {
				setPaymentLanguageContent(checkData.PMT);
			}
		}
	}

	const updateUpcomngMeeting = () => {
		setUpdateAPI(false);
	};

	return (
		<Fragment>
			{showHideLoader ? (
				<CopiriLoader />
			) : (
				<>
					<Row>
						<Col lg='7'>
							<Notifications pageLanguageContent={pageLanguageContent} />
						</Col>
						<Col lg='5'>
							<PostMessageForm pageLanguageContent={pageLanguageContent} />
						</Col>
					</Row>
					<br />
					<Row>
						<Col lg='6'>
							<UpcomingEvents
								updateAPI={updateAPI}
								pageLanguageContent={pageLanguageContent}
								widgetLanguageContent={widgetLanguageContent}
							/>
						</Col>
						<Col lg='6'>
							<AddNewMeeting
								updateUpcomngMeeting={updateUpcomngMeeting}
								pageLanguageContent={pageLanguageContent}
								widgetLanguageContent={widgetLanguageContent}
							/>
						</Col>
					</Row>
					<br />
					<Row>
						<Col lg='6'>
							<RecentPayments paymentLanguageContent={paymentLanguageContent}/>
						</Col>
					</Row>
				</>
			)}
		</Fragment>
	);
};
export default Dashboard;
