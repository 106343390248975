/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, checkValidation } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button, Label, FormGroup } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const PhoneField = props => {
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props;
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [dailCodes, setDailCodes] = useState([]);
	const [inputField, setInputField] = useState({
		phone: '',
		dial: 235
	});
	const [validation, setValidation] = useState({
		error_phone: ''
	});

	useEffect(() => {
		if (props.id) {
			const { phone } = lodgeData;
			getPhoneDailCode();
			setInputField({ ...inputField, phone });
		}
		// eslint-disable-next-line
	}, [props.id]);

	/* function to get the input values */
	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	/* function to get the dial codes */
	function getPhoneDailCode() {
		copiriPortalRequest('/canon/dialcodes', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setDailCodes(response.data);
				}
			})
			.catch(error => {});
	}

	/* function to update the phone filed on lodge modal */
	function updateData() {
		if (checkDataChange) {
			let errors = { ...validation };
			var errorCount = 0;
			var checkError = checkValidation(inputField['phone'], 'phone');
			if (!inputField.phone.trim()) {
				errors.error_phone = pageLanguageContent['CLD071'];
				errorCount++;
			} else if (!checkError) {
				errors.error_phone = pageLanguageContent['CLD072'];
				errorCount++;
			} else {
				errors.error_phone = '';
			}
			setValidation(errors);
			if (errorCount <= 0) {
				var sendFormData = {};
				var sendData = {};
				sendFormData['type'] = '';
				sendFormData['code'] = inputField['dial'];
				sendFormData['number'] = inputField['phone'];
				sendData['value'] = sendFormData;
				setShowLoader(true);
				copiriPortalRequest('/organizations/' + id + '/phone', config.app_version, 'patch', sendData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							closeModal();
							updateLodgeModal('phone', inputField['phone']);
						}
					})
					.catch(error => {
						toast.error(error.response.data.result);
						setShowLoader(false);
					});
			}
		}
	}

	return (
		<Row>
			<Col lg={3}>
				<FormGroup>
					<Label for='dial'>Code</Label>
					<select
						id='dial'
						label='dial'
						type='text'
						onChange={inputsHandler}
						value={inputField.dial}
						name='dial'
						className='form-control'
					>
						{dailCodes.length > 0 &&
							dailCodes.map((item, i) => (
								<option value={item.id} key={item.id}>
									+ {item.dialcode}
								</option>
							))}
					</select>
				</FormGroup>
			</Col>
			<Col lg={9}>
				<FormGroupInput
					id='phone'
					label={pageLanguageContent['CLD013'] ? pageLanguageContent['CLD013'] : ''}
					placeholder=''
					type='text'
					name='phone'
					onChange={inputsHandler}
					value={inputField.phone}
				/>
				{validation.error_phone && <p className='formValidationError'>{validation.error_phone}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default PhoneField;
