/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';

// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
const UnsavedDataModal = ({ showHide, className, onClick, count, path, updateModal }) => {
	return (
		<div>
			<Modal isOpen={showHide} toggle={onClick}>
				<ModalBody>
					<Form>
						<Row>
							<Col md={12}>
								<p>It looks like you're in the middle of a search... do you want to discard it?</p>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={onClick}>
						No, go back
					</Button>
					<Button color='primary' onClick={updateModal}>
						Yes, leave
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default UnsavedDataModal;
