/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, copiriAxiosGet } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
const AddEditProfileEmailModal = props => {
	const { data, type, inputField, setInputField, pageLanguageContent } = props;
	const [validation, setValidation] = useState({
		error_email: ''
	});
	useEffect(() => {
		if (data) {
			if (type === 'edit_email') {
				const { email } = data;
				setInputField({ ...inputField, email });
			}
		}
		// eslint-disable-next-line
	}, [data, type]);

	const inputsHandler = e => {
		setInputField({ [e.target.name]: e.target.value });
	};

	function updateProfileEmail() {
		let errors = { ...validation };
		var errorCount = 0;
		if (!inputField.email.trim()) {
			errors.error_email = pageLanguageContent['PRL026'];
			errorCount++; // eslint-disable-next-line
		} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputField.email)) {
			errors.error_email = pageLanguageContent['PRL031'];
			errorCount++;
		} else {
			errors.error_email = '';
		}
		setValidation(errors);
		if (errorCount <= 0) {
			var sendFormData = {};
			sendFormData['email'] = inputField['email'];
			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
				.then(response => {
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success('Success');
						props.onClick();
						props.updateModal(type, data['id']);
					}
				})
				.catch(error => {});
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick} className='email_modal'>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{pageLanguageContent['PRL108'] ? pageLanguageContent['PRL108'] : ''}
				</ModalHeader>
				<ModalBody>
					<Row>
						{!data.email_validated && (
							<Col lg={12}>
								<div className='alert alert-warning d-flex' role='alert'>
									<i className='fas fa-exclamation-triangle' style={{ margin: '8px 6px 0 0' }}></i>
									<div>
										<div className='cardText' style={{ marginTop: '3px' }}>
											{pageLanguageContent['PRL109'] ? pageLanguageContent['PRL109'] : ''}
										</div>
										<Button color='' className='btn-warn mr-2'>
											{pageLanguageContent['PRL110'] ? pageLanguageContent['PRL110'] : ''}
										</Button>
										<Button color='' className='btn-warn-click'>
											{pageLanguageContent['PRL111'] ? pageLanguageContent['PRL111'] : ''}
										</Button>
									</div>
								</div>
							</Col>
						)}
						<Col lg={12}>
							<FormGroupInput
								id='email'
								label={pageLanguageContent['PRL112'] ? pageLanguageContent['PRL112'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.email}
								name='email'
							/>
							{validation.error_email && <p className='formValidationError'>{validation.error_email}</p>}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateProfileEmail}>
						{pageLanguageContent['PRL101'] ? pageLanguageContent['PRL101'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default AddEditProfileEmailModal;
