// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse, Row, Col } from 'reactstrap'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import wallet_pass from '../../assets/img/logos/wallet_pass.png'; // eslint-disable-next-line
import { copy } from './Common';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
const WalletPassAccordionItem = ({ faq, active, onToggle, currentIndex, pageLanguageContent,profileId }) => {
	const { question } = faq;
	const [disableButton, setDisableButton] = useState(false);
	let langData = JSON.parse(localStorage.getItem('idiom'));
	let pubItemData = langData?.PUB
	/*function to wallet pass issue */
	function getWalletPass() {
		setDisableButton(true);
		copiriPortalRequest('/users/' + profileId + '/walletpass', config.app_version, 'get').then(response => {
			setDisableButton(false);
			toast.success(pageLanguageContent['PRL143']);
		})
		.catch(error => {
			setDisableButton(false);
			toast.error(pubItemData['PUB009']?pubItemData['PUB009']:'');
		});
	}
	return (
		<Card className='shadow-none border rounded-0'>
			<CardHeader
				onClick={onToggle}
				className={`py-2 border-none cursor-pointer ${active ? 'activeMemebrShipHeader' : 'deActiveMemebrShipHeader'}`}
			>
				<span className='font-weight-medium text-dark text-sans-serif pl-2'>{question}</span>
				<div className='openHideAccordian'>
					{' '}
					{active ? <i className='fas fa-minus'></i> : <i className='fas fa-plus'></i>}
				</div>
			</CardHeader>
			<Collapse isOpen={active}>
				<CardBody className='pt-3'>
					<div className='profile-card-wallet-pass'>
						<div className='d-flex align-items-center blue-box'>
							<img src={wallet_pass} alt='wallet_pass' />
							<div className='content-box pl-2'>
								<span>{pageLanguageContent['PRL139'] ? pageLanguageContent['PRL139'] : ''}</span>
								<h4>{pageLanguageContent['PRL140'] ? pageLanguageContent['PRL140'] : ''}</h4>
							</div>
							<button className='btn mt-1 btn-info ml-auto' onClick={() => getWalletPass()} disabled={disableButton}>
								{pageLanguageContent['PRL141'] ? pageLanguageContent['PRL141'] : ''}
							</button>
						</div>
					</div>
				</CardBody>
			</Collapse>
		</Card>
	);
};

export default WalletPassAccordionItem;
