import React, { useState } from 'react';
import PropTypes, { array, string } from 'prop-types';
import { isIterableArray } from '../../helpers/utils';
import Flex from './Flex';
import no_gl_seal_light from '../../assets/img/generic/no_gl_seal_light.png';
import user_avatar from '../../assets/img/team/avatar.svg';

const Avatar = ({ size, rounded, src, name, emoji, className, mediaClass, isExact, icon }) => {
	const classNames = ['avatar', `avatar-${size}`, className].join(' ');
	const mediaClasses = [rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ');

	const [isImage, setIsImage] = useState('');

	const checkState = (e, s) => {
		if (s) {
			setIsImage(true);
		} else {
			setIsImage(false);
		}
	};

	const getAvatar = () => {
		if (src) {
			if (isIterableArray(src)) {
				return (
					<div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
						<div className='w-50 border-right'>
							<img src={src[0]} alt='' />
						</div>
						<div className='w-50 d-flex flex-column'>
							<img src={src[1]} alt='' className='h-50 border-bottom' />
							<img src={src[2]} alt='' className='h-50' />
						</div>
					</div>
				);
			} else {
				if (isImage === false) {
					return <img className={mediaClasses} src={user_avatar} alt='No Avatar Available for This User' />;
				}
				return (
					<img
						className={mediaClasses}
						src={src}
						alt=''
						onLoad={() => checkState(this, true)}
						onError={() => checkState(this, false)}
					/>
				);
			}
		}

		if (name) {
			return (
				<div className={`avatar-name ${mediaClasses}`}>
					<span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
				</div>
			);
		}

		if (icon) {
			return (
				<Flex className={`avatar-name ${mediaClasses} flex-center`}>
					<i className='fad fa-user-circle'></i>
				</Flex>
			);
		}

		return <img className={mediaClasses} src={no_gl_seal_light} alt='No Seal Available' />;
	};

	return <div className={classNames}>{getAvatar()}</div>;
};

Avatar.propTypes = {
	size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
	rounded: PropTypes.string,
	src: PropTypes.oneOfType([array, string]),
	name: PropTypes.string,
	emoji: PropTypes.string,
	className: PropTypes.string,
	mediaClass: PropTypes.string,
	isExact: PropTypes.bool
};

Avatar.defaultProps = {
	size: 'xl',
	rounded: 'circle',
	emoji: '',
	isExact: false
};

export default Avatar;
