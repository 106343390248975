/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const NotesField = props => {
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props;
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		notes: ''
	});
	const [validation, setValidation] = useState({
		error_notes: ''
	});

	useEffect(() => {
		if (props.id) {
			const { notes } = lodgeData;
			setInputField({ ...inputField, notes });
		}
		// eslint-disable-next-line
	}, [props.id]);

	/* function to get the input values */
	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	/* function to update the notes field on lodge modal */
	function updateData() {
		if (checkDataChange) {
			let errors = { ...validation };
			var errorCount = 0;
			if (!inputField.notes.trim()) {
				errors.error_notes = pageLanguageContent['CLD071'];
				errorCount++;
			} else {
				errors.error_notes = '';
			}
			setValidation(errors);
			if (errorCount <= 0) {
				var sendFormData = {};
				var sendData = {};
				sendFormData['content'] = inputField['notes'];
				sendData['value'] = sendFormData;
				setShowLoader(true);
				copiriPortalRequest('/organizations/' + id + '/notes', config.app_version, 'patch', sendData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							closeModal();
							updateLodgeModal('notes', inputField['notes']);
						}
					})
					.catch(error => {
						setShowLoader(false);
					});
			}
		}
	}

	return (
		<Row>
			<Col lg={12}>
				<FormGroupInput
					id='notes'
					label={pageLanguageContent['CLD033'] ? pageLanguageContent['CLD033'] : ''}
					placeholder=''
					type='textarea'
					name='notes'
					onChange={inputsHandler}
					value={inputField.notes}
				/>
				{validation.error_notes && <p className='formValidationError'>{validation.error_notes}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default NotesField;
