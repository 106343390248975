/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Table } from 'reactstrap';
import FormGroupInput from '../../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, copiriAxiosGet } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
const MemberShipInformationModal = props => {
	const { type, modalHeadingText, pageLanguageContent, membershipData, profileId } = props;
	const [roles, setRoles] = useState([]); // eslint-disable-next-line
	const [roleHistory, setRoleHistory] = useState([]); // eslint-disable-next-line
	const [roleStatus, setRoleStatus] = useState([]);
	const [checkHistory, setCheckHistory] = useState(false);
	const [inputField, setInputField] = useState({
		number: '',
		is_admin: '',
		primary: '',
		office: '',
		status_id: ''
	});

	const [validation, setValidation] = useState({
		error_number: '',
		error_office: '',
		error_status_id: ''
	});

	useEffect(() => {
		if (props.showHide) {
			var number = membershipData['number'];
			var status_id = membershipData['status_id'];
			setInputField({ ...inputField, number, status_id });
			setValidation([]);
			if (localStorage.getItem('active_profile_id')) {
				getRoleHistory(localStorage.getItem('active_profile_id'));
			}
			if (type && type === 'memberShipOffice') {
				if (roles.length <= 0) {
					getRoles();
				}
			}

			if (type && type === 'memberShipStatus') {
				getStatus();
			}
		}
		// eslint-disable-next-line
	}, [props.showHide]);

	const getStatus = () => {
		copiriPortalRequest('/canon/types/status?existing=' + membershipData.status_id, config.app_version, 'get')
			.then(response => {
				const result = Object.entries(response.data).map(([name, obj]) => ({ name, ...obj }));
				if (result && result.length > 0) {
					setRoleStatus(result);
				}
			})
			.catch(error => {});
	};

	const getRoleHistory = id => {
		copiriPortalRequest('/users/' + profileId + '/role/history', config.app_version, 'get')
			.then(response => {
				setCheckHistory(true);
				if (response.data.history.length > 0) {
					setRoleHistory(response.data.history);
				}
			})
			.catch(error => {
				setCheckHistory(false);
			});
	};

	const getRoles = () => {
		var org_id = localStorage.getItem('selected_org_id');
		copiriPortalRequest('/organizations/' + org_id + '/roles', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setRoles(response.data);
				}
			})
			.catch(error => {});
	};

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	function updateMembershipData() {
		let errors = { ...validation };
		var errorCount = 0;
		if (type === 'memberShipNumber') {
			if (!inputField.number.trim()) {
				errors.error_number = pageLanguageContent['PRL026'];
				errorCount++;
			} else {
				errors.error_number = '';
			}
		}

		if (type === 'memberShipStatus') {
			if (!inputField.status_id.trim()) {
				errors.error_status_id = pageLanguageContent['PRL026'];
				errorCount++;
			} else {
				errors.error_status_id = '';
			}
		}

		if (type === 'memberShipOffice') {
			if (!inputField.office.trim()) {
				errors.error_office = pageLanguageContent['PRL026'];
				errorCount++;
			} else {
				errors.error_office = '';
			}
		}
		setValidation(errors);
		var sendFormData = {};
		if (errorCount <= 0) {
			if (type === 'memberShipNumber') {
				sendFormData['number'] = inputField['number'];
			}
			if (type === 'memberShipStatus') {
				sendFormData['status_id'] = inputField['status_id'];
			}
			if (type === 'memberShipOffice') {
				sendFormData['office'] = inputField['office'];
			}
			copiriPortalRequest(
				'/users/' + profileId + '/memberships/' + membershipData['id'],
				config.app_version,
				'patch',
				sendFormData
			)
				.then(response => {
					toast.success('Success');
					props.onClick();
				})
				.catch(error => {
					toast.error(error.response.result);
				});
		} else {
		}
	}
	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick} className='email_modal'>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{modalHeadingText}
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={12}>
							{type && type === 'memberShipNumber' && (
								<FormGroupInput
									id='MemebrshipNumber'
									label={pageLanguageContent['PRL123'] ? pageLanguageContent['PRL123'] : ''}
									type='text'
									name='number'
									onChange={inputsHandler}
									value={inputField.number}
								/>
							)}
							{validation.error_number && <p className='formValidationError'>{validation.error_number}</p>}

							{type && type === 'memberShipStatus' && (
								<FormGroup>
									<Label for='officeName'>{pageLanguageContent['PRL125'] ? pageLanguageContent['PRL125'] : ''}</Label>
									<select
										className='form-control decorated'
										onChange={inputsHandler}
										value={inputField.status_id}
										name='status_id'
									>
										<option value=''>Select Status</option>
										{roleStatus.length > 0 &&
											roleStatus.map((item, i) => (
												<option value={item.id} data-rc={item.id} key={i}>
													{item.name}
												</option>
											))}
									</select>
								</FormGroup>
							)}
							{validation.error_status_id && <p className='formValidationError'>{validation.error_status_id}</p>}
							{type && type === 'memberShipOffice' && (
								<FormGroup>
									<Label for='officeName'>{pageLanguageContent['PRL124'] ? pageLanguageContent['PRL124'] : ''}</Label>
									<select
										className='form-control decorated'
										onChange={inputsHandler}
										name='office'
										value={inputField.office}
									>
										<option value=''>Select office</option>
										{roles.length > 0 &&
											roles.map((item, i) => (
												<option value={item.id} data-rc={item.id} key={i}>
													{item.name}
												</option>
											))}
									</select>
								</FormGroup>
							)}
							{validation.error_office && <p className='formValidationError'>{validation.error_office}</p>}
							{type && type === 'history' && (
								<Table
									striped
									className='table table-dashboard table-striped table-sm fs--1 border mb-0 table-dashboard-th-nowrap'
								>
									<thead className=''>
										<tr className='bg-200 text-900'>
											<th className='text-left pl-2'>Date</th>
											<th className='text-left pl-2'>Name</th>
											{/*<th className="text-left pl-2" >
                    To
                  </th>
                  <th className="text-left pl-2" >
                    Changed By
                  </th> */}
										</tr>
									</thead>
									<tbody>
										{checkHistory && (
											<>
												{roleHistory.length > 0 ? (
													roleHistory.map((item, i) => (
														<tr key={i}>
															<td>{item['creator'] ? item['creator']['created'] : ''}</td>
															<td>{item['name']}</td>
															{/*<td>
                          Date Changed
                        </td>
                        <td>
                          Aman
                        </td>*/}
														</tr>
													))
												) : (
													<tr>
														<td>{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}</td>
													</tr>
												)}
											</>
										)}
									</tbody>
								</Table>
							)}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					{type && type !== 'history' && (
						<Button color='primary' onClick={updateMembershipData}>
							{pageLanguageContent['PRL101'] ? pageLanguageContent['PRL101'] : ''}
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</>
	);
};
export default MemberShipInformationModal;
