import React, { useEffect } from 'react';

function ColumnChart(props) {
	const divId = props.chartId;
	const dataProvider = props.data;
	const divHeight = props.height ? props.height : '45px'; 
	const loadAmCharts = () => {
		Promise.all([
			import('@amcharts/amcharts4/core'),
			import('@amcharts/amcharts4/charts'),
			import('@amcharts/amcharts4/themes/animated')
		]).then(modules => {
			let am4core = modules[0];
			let am4charts = modules[1];
			let am4themes_animated = modules[2].default;
			am4core.addLicense('CH259299157');
			am4core.useTheme(am4themes_animated);
			var chart = am4core.create(divId, am4charts.XYChart);
			chart.plotContainer.background.strokeWidth = 0;
			chart.plotContainer.background.strokeOpacity = 0;
		    chart.data = dataProvider;
		    chart.titles.template.fontSize = 10;
		    chart.titles.template.textAlign = "left";
		    chart.titles.template.isMeasured = false;
		    chart.padding(20, 5, 2, 5);
		    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
		    dateAxis.renderer.grid.template.disabled = true;
		    dateAxis.renderer.labels.template.disabled = true;
		    dateAxis.startLocation = 0.5;
		    dateAxis.endLocation = 0.7;
		    dateAxis.cursorTooltipEnabled = false;

		    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		    valueAxis.min = 0;
		    valueAxis.renderer.grid.template.disabled = true;
		    valueAxis.renderer.baseGrid.disabled = true;
		    valueAxis.renderer.labels.template.disabled = true;
		    valueAxis.cursorTooltipEnabled = false;

		    chart.cursor = new am4charts.XYCursor();
		    chart.cursor.lineY.disabled = true;
		    chart.cursor.behavior = "none";

		    let series = chart.series.push(new am4charts.LineSeries());
		    series.tooltipText = "{date}: [bold]{amount}";
		    series.dataFields.dateX = "date";
		    series.dataFields.valueY = "amount";
		    series.tensionX = 0.8;
		    series.strokeWidth = 2;
		    series.stroke = '#0b38b6';

		    // render data points as bullets
		    let bullet = series.bullets.push(new am4charts.CircleBullet());
		    bullet.circle.opacity = 0;
		    bullet.circle.fill = '#0b38b6';
		    bullet.circle.propertyFields.opacity = "opacity";
		    bullet.circle.radius = 3;
			chart.current = chart;
		});
	};

	useEffect(() => {
		loadAmCharts();
		// eslint-disable-next-line
	}, []);

	return (
			<div id={divId} style={{ left: '0', height: divHeight, minHeight: divHeight,position:'absolute',bottom:'-3px',zIndex: '99' }}></div>
	);
}
export default ColumnChart;
