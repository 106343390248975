/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, getLanguagePackUrl } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader'; // eslint-disable-next-line
import default_image from '../../assets/img/logos/harvard.png'; // eslint-disable-next-line
import './style.css'; // eslint-disable-next-line
const MeetingModal = ({
	showHide,
	className,
	onClick,
	count,
	meetingId = 564743139,
	pageLanguageContent,
	widgetLanguageContent
}) => {
	// eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(false); // eslint-disable-next-line
	const [meetingData, setMeetingData] = useState([]);
	useEffect(() => {
		if (showHide) {
			getMeetingDetail(meetingId);
		}
		// eslint-disable-next-line
	}, [showHide]);

	function getMeetingDetail(id) {
		setShowHideLoader(true);
		copiriPortalRequest('/meetings/' + id, config.app_version, 'get')
			.then(response => {
				setShowHideLoader(false);
				setMeetingData(response.data);
			})
			.catch(error => {
				setShowHideLoader(false);
				setMeetingData([]);
			});
	}
	return (
		<div>
			<Modal
				isOpen={showHide}
				toggle={onClick}
				className=''
				id='meeting_modal'
				size='lg'
				aria-labelledby='example-modal-sizes-title-lg'
			>
				{showHideLoader ? (
					<CopiriLoader />
				) : (
					<>
						<ModalHeader toggle={onClick} style={{ color: '#fff' }}>
							{widgetLanguageContent['WMT022'] ? widgetLanguageContent['WMT022'] : ''}
						</ModalHeader>
						<ModalBody className='p-0 grey-bg'>
							<CardBody className='pt-2'>
								<Row>
									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT006'] ? widgetLanguageContent['WMT006'] : ''}:</h6>
											<p>{meetingData ? meetingData.name : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT023'] ? widgetLanguageContent['WMT023'] : ''}:</h6>
											<p>{meetingData ? meetingData.code : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT024'] ? widgetLanguageContent['WMT024'] : ''}:</h6>
											<p>{meetingData ? meetingData.type : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT034'] ? widgetLanguageContent['WMT034'] : ''}:</h6>
											<p>{meetingData ? meetingData.heads : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT026'] ? widgetLanguageContent['WMT026'] : ''}:</h6>
											<p>{meetingData ? meetingData.org_name : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT027'] ? widgetLanguageContent['WMT027'] : ''}:</h6>
											<p>{meetingData ? meetingData.checked_in : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT028'] ? widgetLanguageContent['WMT028'] : ''}:</h6>
											<p>{meetingData ? meetingData.description : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT029'] ? widgetLanguageContent['WMT029'] : ''}:</h6>
											<p>{meetingData ? meetingData.time : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT030'] ? widgetLanguageContent['WMT030'] : ''}:</h6>
											<p>{meetingData ? meetingData.eventUrl : ''}</p>
										</div>
									</Col>

									<Col lg={6} className=''>
										<div className='lodge-middle-box'>
											<h6>{widgetLanguageContent['WMT031'] ? widgetLanguageContent['WMT031'] : ''}:</h6>
											<p>{meetingData ? meetingData.eventPasscode : ''}</p>
										</div>
									</Col>
								</Row>
							</CardBody>
						</ModalBody>

						<ModalFooter>
							<Row className='lodges-bottom-footer'>
								<ul>
									{meetingData &&
										meetingData.files &&
										meetingData.files.length > 0 &&
										meetingData.files.map((item, i) => (
											<li key={i}>
												<a href={item.url} target='_blank' rel='noopener noreferrer'>
													<img src={item.url} alt={'img'} />
												</a>
											</li>
										))}
								</ul>
							</Row>
						</ModalFooter>
					</>
				)}
			</Modal>
		</div>
	);
};
export default MeetingModal;
