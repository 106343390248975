import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical'; // eslint-disable-next-line
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils'; // eslint-disable-next-line
import { copiriAxiosPost, checkSiteUrlAccess, sessionLogout } from '../components/api/api'; // eslint-disable-next-line
import config from '../components/config/config'; // eslint-disable-next-line
import Loader from '../components/common/Loader'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
const DashboardRoutes = loadable(() => import('./DashboardRoutes'));
const DashboardLayout = ({ location, siteType }) => {
	const { isFluid, isVertical, navbarStyle } = useContext(AppContext); // eslint-disable-next-line
	const [checkSession, setCheckSession] = useState(false); // eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(false);
	const [profileDropDownData, setProfileDropDownData] = useState([]); // eslint-disable-next-line
	const [langContetn, setPageLanguageContent] = useState([]);
	const isKanban = getPageName('kanban');
	var checksiteAccessUrl = checkSiteUrlAccess(); //for check which site user is accessing
	useEffect(() => {
		DashboardRoutes.preload();
		checkLoginSession();
		// eslint-disable-next-line
	}, [siteType]);

	useEffect(() => {
		localStorage.setItem('last_url_location', location.pathname); // eslint-disable-next-line
		window.scrollTo(0, 0);
	}, [location.pathname]);

	/* function to check session */
	function checkLoginSession() {
		var token = localStorage.getItem('palaver');
		if (!token) {
			sessionLogout();
		} else {
			setProfileDropDownData([]);
			const formdata = new FormData();
			formdata.append('action', 'my_profile');
			copiriAxiosPost(config.portal_url, formdata)
				.then(response => {
					if (response.data.status === 'LOGOUT') {
					} else {
						setProfileDropDownData(response.data);
						setCheckSession(true);
					}
				})
				.catch(error => {
					setShowHideLoader(false);
					setCheckSession(true);
				});
		}
	}

	return (
		<>
			{checkSession ? (
				<div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
					{isVertical && checksiteAccessUrl === 'portal' && (
						<NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} langContetn={langContetn} />
					)}
					<div className='content'>
						<NavbarTop profileDropDownData={profileDropDownData} langContetn={langContetn} />
						<Switch>
							<Route path='/' exact component={Dashboard} />
							<DashboardRoutes />
						</Switch>
						{checksiteAccessUrl && checksiteAccessUrl === 'portal' && <Footer />}
					</div>
					<SidePanelModal path={location.pathname} />
				</div>
			) : (
				<div className='sessionLoadingIcon'>
					<Loader />
				</div>
			)}
		</>
	);
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
