/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
const EditProfileNameModal = props => {
	const { data, inputField, setInputField, type, pageLanguageContent } = props;
	const [formattedName, setFormattedName] = useState('');
	const [sufixesList, setSufixesList] = useState([]);

	const [validation, setValidation] = useState({
		error_first_name: '',
		error_last_name: '',
		error_middle_name: '',
		error_name_pref: '',
		error_name_suffix: ''
	});

	const [checkChangeData, setCheckChangeData] = useState({
		first_name: false,
		last_name: false,
		middle_name: false,
		name_pref: false,
		suffix_id: false
	});

	useEffect(() => {
		if (data) {
			let initialsErrors = { ...validation };
			setValidation(initialsErrors);
			const { first_name, last_name, middle_name, name_full, name_pref, suffix_id, suffix, second_last_name } = data;
			setInputField({
				...inputField,
				first_name,
				last_name,
				middle_name,
				name_pref,
				name_full,
				suffix_id,
				second_last_name
			});
			setFormattedName(first_name + ' (' + name_pref + ') ' + middle_name + ' ' + last_name + ' ' + suffix);
			getSuffixes();
		}
		// eslint-disable-next-line
	}, [data, setValidation, setInputField]);

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
		setCheckChangeData({ ...checkChangeData, [e.target.name]: true });
	};

	function getSuffixes() {
		copiriPortalRequest('/canon/suffixes', config.app_version, 'get', data)
			.then(response => {
				if (response.data.length > 0) {
					setSufixesList(response.data);
				}
			})
			.catch(error => {});
	}

	function updateProfileName() {
		let errors = { ...validation };
		var errorCount = 0;
		//first name validation
		if (!inputField.first_name.trim()) {
			errors.error_first_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_first_name = '';
		}
		//name preference validation
		if (!inputField.name_pref.trim()) {
			errors.error_name_pref = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_name_pref = '';
		}

		//middle name validation
		if (!inputField.middle_name.trim()) {
			errors.error_middle_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_middle_name = '';
		}
		//last name validation
		if (!inputField.last_name.trim()) {
			errors.error_last_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_last_name = '';
		}

		if (!inputField.suffix_id) {
			errors.error_suffix = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_suffix = '';
		}

		//inputField.suffix
		setValidation(errors);
		if (errorCount <= 0) {
			inputField['name_full'] =
				inputField['first_name'] + ' ' + inputField['middle_name'] + ' ' + inputField['last_name'];
			var sendFormData = {};

			if (checkChangeData['name_pref']) {
				sendFormData['preferred_name'] = inputField['name_pref'];
			}

			if (checkChangeData['first_name']) {
				sendFormData['first_name'] = inputField['first_name'];
			}

			if (checkChangeData['middle_name']) {
				sendFormData['middle_name'] = inputField['middle_name'];
			}

			if (checkChangeData['last_name']) {
				sendFormData['last_name'] = inputField['last_name'];
			}

			if (checkChangeData['suffix_id']) {
				sendFormData['suffix_id'] = inputField['suffix_id'];
			}
			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
				.then(response => {
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success('Success');
						props.onClick();
						props.updateModal(type, data['id'], inputField);
					}
				})
				.catch(error => {});
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick}>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					Edit Name
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={12}>
							<FormGroupInput id='' label='' type='text' value={formattedName} disabled />
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='first_name'
								label={pageLanguageContent['PRL102'] ? pageLanguageContent['PRL102'] : ''}
								name='first_name'
								type='text'
								onChange={inputsHandler}
								value={inputField.first_name}
							/>
							{validation.error_first_name && <p className='formValidationError'>{validation.error_first_name}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='name_pref'
								label={pageLanguageContent['PRL103'] ? pageLanguageContent['PRL103'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.name_pref}
								name='name_pref'
							/>
							{validation.error_name_pref && <p className='formValidationError'>{validation.error_name_pref}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='middle_name'
								label={pageLanguageContent['PRL104'] ? pageLanguageContent['PRL104'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.middle_name}
								name='middle_name'
							/>
							{validation.error_middle_name && <p className='formValidationError'>{validation.error_middle_name}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='last_name'
								label={pageLanguageContent['PRL105'] ? pageLanguageContent['PRL105'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.last_name}
								name='last_name'
							/>
							{validation.error_last_name && <p className='formValidationError'>{validation.error_last_name}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='second_last_name'
								label={pageLanguageContent['PRL106'] ? pageLanguageContent['PRL106'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.second_last_name}
								name='second_last_name'
							/>
						</Col>

						<Col lg={6}>
							<FormGroup>
								<Label for='suffix'>{pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}</Label>
								<select
									id='suffix'
									label={pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}
									type='text'
									onChange={inputsHandler}
									value={inputField.suffix_id}
									name='suffix_id'
									className='form-control'
								>
									<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
									{sufixesList.length > 0 &&
										sufixesList.map((item, i) => (
											<option value={item.id} key={item.id}>
												{item.name}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_suffix && <p className='formValidationError'>{validation.error_suffix}</p>}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateProfileName}>
						{pageLanguageContent['PRL101'] ? pageLanguageContent['PRL101'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default EditProfileNameModal;
