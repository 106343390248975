import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { copiriPortalRequest } from '../api/api';
import config from '../../config';
import CopiriLoader from '../common/CopiriLoader';
import './style.css';
import default_image from '../../assets/img/logos/no_gl_seal_light.png';
import loader_image from '../../assets/img/loader/small_loader.gif';
import MeetingModal from './MeetingModal';
import ReactPaginate from 'react-paginate';

const UpcomingEvents = props => {
	// eslint-disable-next-line
	const { updateAPI, pageLanguageContent, widgetLanguageContent } = props; // eslint-disable-next-line
	const [upcomingMeetingData, setUpcomingMeetingData] = useState([]); // eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(false);
	const [checkAPI, setCheckAPI] = useState(false);
	const [showLoader, setShowLoader] = useState(true); // eslint-disable-next-line
	const [showHide, setShowHide] = useState(false);
	const [meetingId, setMeetingId] = useState('');
	const [pagiArray, setPagiArray] = useState([]); // eslint-disable-next-line
	const [selectedPage, setSelectedPage] = useState(''); // eslint-disable-next-line
	useEffect(() => {
		getUpcomingMeetings();
		if (updateAPI) {
			getUpcomingMeetings();
		}
		// eslint-disable-next-line
	}, []);

	function handlePagination(value) {
		var startVal = value.selected * 20;
		getUpcomingMeetings(false, startVal, 20);
	}

	function getUpcomingMeetings(updatePagination = true, start = 0, count = 20) {
		setCheckAPI(false);
		setUpcomingMeetingData([]);
		var org_id = localStorage.getItem('selected_org_id');
		//var org_id = 794 ;
		setShowLoader(true);
		copiriPortalRequest(
			'/organizations/' + org_id + '/meetings?start=' + start + '&count=' + count,
			config.app_version,
			'get'
		)
			.then(response => {
				if (updatePagination) {
					setPagiArray(response.data.count / 20);
				}
				setShowLoader(false);
				setCheckAPI(true);
				if (response.data.meetings.length > 0) {
					setUpcomingMeetingData(response.data.meetings);
				} else {
					setUpcomingMeetingData([]);
				}
			})
			.catch(error => {
				setShowLoader(false);
				setCheckAPI(true);
				setUpcomingMeetingData([]);
			});
	}

	/* function to get the image*/
	function getImage(filesArr) {
		var img = default_image;
		if (filesArr.length > 0) {
			for (var k = 0; k < filesArr.length; k++) {
				if (filesArr[k]['type'] === 'image') {
					img = filesArr[k]['url'];
				}
			}
		}
		return img;
	}

	/* function to close modal*/
	const closeModal = () => {
		setShowHide(false);
	};
	/* function to show modal*/
	function showModal(id) {
		setMeetingId(id);
		setShowHide(true);
	}

	function disableTab() {
		if (selectedPage <= 1) {
			return 'disable-prev-tab';
		}
		if (selectedPage === Math.round(pagiArray)) {
			return 'disable-next-tab';
		}
		return '';
	}

	const renderPagination = (className = '') => {
		return (
			<div
				className={`d-inline-block ${className} ${
					upcomingMeetingData && upcomingMeetingData.length ? '' : 'ponter-none opacity-50'
				}`}
			>
				<ReactPaginate
					previousLabel={widgetLanguageContent['WMT032'] ? widgetLanguageContent['WMT032'] : ''}
					nextLabel={widgetLanguageContent['WMT009'] ? widgetLanguageContent['WMT009'] : ''}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={Math.ceil(pagiArray) === 0 ? 1 : Math.ceil(pagiArray)}
					marginPagesDisplayed={2}
					pageRangeDisplayed={3}
					forcePage={selectedPage}
					onPageChange={handlePagination}
					containerClassName={`pagination react-paginate ${disableTab()}`}
					activeClassName={'active'}
				/>
			</div>
		);
	};

	return (
		<Card className='h-100 mt-3 mt-lg-0'>
			{showHideLoader ? (
				<CopiriLoader />
			) : (
				<>
					<CardHeader className='bg-light d-flex justify-content-between'>
						<h5 className='mb-0'>
							{widgetLanguageContent['WMT033'] ? widgetLanguageContent['WMT033'] : ''}
							{showLoader && (
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
									id={''}
									style={{ marginLeft: '10px', position: 'absolute', marginTop: '-5px' }}
								/>
							)}
						</h5>
						{renderPagination('')}
					</CardHeader>
					<CardBody className='fs--1 upcomingEvents'>
						<Row>
							{upcomingMeetingData &&
								upcomingMeetingData.length > 0 &&
								upcomingMeetingData.map((item, i) => (
									<Col md={12} className='h-100' key={i}>
										<div className='media'>
											<div className='calendar'>
												{item.files.length > 0 && <></>}
												<span className='calendar-month'></span>
												<span className='calendar-day'>
													<img src={getImage(item.files)} width='35' height='35' alt='img' />
												</span>
											</div>
											<div
												className='position-relative pl-3 media-body'
												onClick={() => showModal(item.id)}
												style={{ cursor: 'pointer' }}
											>
												<h6 className='fs-0 mb-0'>{item.name}</h6>
												<p className='text-1000 mb-0'>
													{widgetLanguageContent['WMT023'] ? widgetLanguageContent['WMT023'] : ''}: {item.code}
												</p>
												<p className='text-1000 mb-0'>
													{widgetLanguageContent['WMT034'] ? widgetLanguageContent['WMT034'] : ''}: {item.heads}
												</p>

												<p className='text-1000 mb-0'>
													{widgetLanguageContent['WMT035'] ? widgetLanguageContent['WMT035'] : ''}: {item.time}
												</p>
												{/*<p className="mb-1">Organized by <a className="text-700" href="/pages/events#!">Organized by AID MIT</a></p>
                    <p className="mb-0">The Liberty Warehouse, New Yourk</p>*/}

												<hr className='border-dashed border-bottom-0' />
											</div>
										</div>
									</Col>
								))}

							{upcomingMeetingData && upcomingMeetingData.length <= 0 && checkAPI && (
								<Col md={12} className='h-100'>
									<div className='1'>
										<h5 style={{ textAlign: 'center' }}>
											{widgetLanguageContent['WMT036'] ? widgetLanguageContent['WMT036'] : ''}
										</h5>
									</div>
								</Col>
							)}
						</Row>
						<MeetingModal
							showHide={showHide}
							onClick={closeModal}
							meetingId={meetingId}
							test='test'
							pageLanguageContent={pageLanguageContent}
							widgetLanguageContent={widgetLanguageContent}
						/>
					</CardBody>
				</>
			)}
		</Card>
	);
};

export default UpcomingEvents;
