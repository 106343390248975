/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader'; // eslint-disable-next-line
import default_avatar from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import classnames from 'classnames';
import UserProfilePersonalInforamtion from './UserProfilePersonalInforamtion';
import UserMemebrShipCardInformation from './UserMemebrShipCardInformation';
import UserMemebrShipInformation from './UserMemebrShipInformation';
const UserProfileContentSection = ({ data, pageLanguageContent, updateData, profileId }) => {
	const [currentActiveTab, setCurrentActiveTab] = useState('1');
	const [showTabData, setShowTabData] = useState(false);
	const [showMemberShipTabData, setShowMemberShipTabData] = useState(false);
	// Toggle active state for Tab
	const toggle = tab => {
		if (tab === '3') {
			setShowTabData(true);
		} else {
			setShowTabData(false);
		}

		if (tab === '2') {
			setShowMemberShipTabData(true);
		} else {
			setShowMemberShipTabData(false);
		}

		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};

	return (
		<Row className='profile-sidebtn'>
			<Col lg={3}>
				<Nav variant='pills' className='flex-column'>
					<NavItem>
						<NavLink
							className={classnames({ active: currentActiveTab === '1' })}
							onClick={() => {
								toggle('1');
							}}
						>
							{pageLanguageContent['PRL041'] ? pageLanguageContent['PRL041'] : ''}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: currentActiveTab === '2' })}
							onClick={() => {
								toggle('2');
							}}
						>
							{pageLanguageContent['PRL080'] ? pageLanguageContent['PRL080'] : ''}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: currentActiveTab === '3' })}
							onClick={() => {
								toggle('3');
							}}
						>
							{pageLanguageContent['PRL081'] ? pageLanguageContent['PRL081'] : ''}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: currentActiveTab === '4' })}
							onClick={() => {
								toggle('4');
							}}
						>
							{pageLanguageContent['PRL082'] ? pageLanguageContent['PRL082'] : ''}
						</NavLink>
					</NavItem>
				</Nav>
			</Col>
			<Col lg={9}>
				<TabContent activeTab={currentActiveTab}>
					<TabPane tabId='1'>
						<UserProfilePersonalInforamtion
							data={data}
							updateData={updateData}
							pageLanguageContent={pageLanguageContent}
						/>
					</TabPane>
					<TabPane tabId='2'>
						<UserMemebrShipInformation
							data={data}
							pageLanguageContent={pageLanguageContent}
							profileId={profileId}
							showMemberShipTabData={showMemberShipTabData}
						/>
					</TabPane>
					<TabPane tabId='3'>
						<UserMemebrShipCardInformation
							data={data}
							pageLanguageContent={pageLanguageContent}
							profileId={profileId}
							showTabData={showTabData}
						/>
					</TabPane>
					<TabPane tabId='4'>
						<Row>
							<Col sm='12'>
								<h5>Activity</h5>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			</Col>
		</Row>
	);
};
export default UserProfileContentSection;
