/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button, Label, FormGroup } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const LanguageField = props => {
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props;
	const [langData, setLangData] = useState([]); // eslint-disable-next-line
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		language: ''
	});
	useEffect(() => {
		if (props.id && langData.length <= 0) {
			const { language } = lodgeData;
			if (language !== 'Language not provided.') setInputField({ ...inputField, language });
			getLanguages();
		}
		// eslint-disable-next-line
	}, [props.id]);

	const [validation, setValidation] = useState({
		error_language: ''
	});

	/* function to get the input values */
	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	/* function to get all languages */
	function getLanguages() {
		copiriPortalRequest('/canon/languages', config.app_version, 'get')
			.then(response => {
				if (response.data.result.length > 0) {
					setLangData(response.data.result);
				}
			})
			.catch(error => {});
	}

	function updateData() {
		var sendFormData = {};
		var sendData = {};
		sendFormData['string'] = inputField['language'];
		sendData['value'] = sendFormData;
		sendData['type'] = 'Language';
		let errors = { ...validation };
		var errorCount = 0;
		if (!inputField.language.trim()) {
			errors.error_language = pageLanguageContent['CLD071'];
			errorCount++;
		} else {
			errors.error_language = '';
		}

		setValidation(errors);
		if (errorCount <= 0) {
			setShowLoader(true);
			copiriPortalRequest('/organizations/' + id + '/additional-info', config.app_version, 'patch', sendData)
				.then(response => {
					setShowLoader(false);
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success(pageLanguageContent['CLD119']);
						closeModal();
						updateLodgeModal('language', inputField['language']);
					}
				})
				.catch(error => {
					toast.error(error.response.data.result);
					setShowLoader(false);
				});
		}
	}
	return (
		<Row>
			<Col lg={12}>
				<FormGroup>
					<Label for='language'>{pageLanguageContent['CLD029'] ? pageLanguageContent['CLD029'] : ''}</Label>
					<select
						className='form-control'
						id='selectCountry'
						name='language'
						onChange={inputsHandler}
						value={inputField.language}
					>
						<option value=''>{pageLanguageContent['CLD125'] ? pageLanguageContent['CLD125'] : ''}</option>
						{langData.length > 0 &&
							langData.map((item, i) => (
								<option value={item.id} key={item.id}>
									{item.name}
								</option>
							))}
					</select>
				</FormGroup>
				{validation.error_language && <p className='formValidationError'>{validation.error_language}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default LanguageField;
