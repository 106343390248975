import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, Input, Dropdown, DropdownToggle, Media } from 'reactstrap';
import Avatar from '../common/Avatar';
import avtar_img from '../../assets/img/team/avatar.svg';
// eslint-disable-next-line
import org_img from '../../assets/img/team/no_gl_seal_light.png'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api';
import config from '../config/config';
import UserProfileModal from '../common/UserProfileModal';
import loader_image from '../../assets/img/loader/small_loader.gif';
import LodgeModal from '../lodges/LodgeModal';
import { Prompt } from 'react-router';
import UnsavedDataModal from './UnsavedDataModal';
import { useHistory } from 'react-router-dom';
const UserSearchBox = ({ autoCompleteItem }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [showHide, setShowHide] = useState(false);
	const [searchInputValue, setSearchInputValue] = useState('');
	const [profileId, setProfileId] = useState('');
	const [memberData, setMemberData] = useState([]);
	const [searchOrgData, setSearchOrgData] = useState([]);
	const [showHideLoader, setShowHideLoader] = useState(true);
	const [showResult, setShowResult] = useState(false);
	const [selectedOrgName, setSelectedOrgName] = useState('');
	const [id, setId] = useState('');
	const [showHideLodgeModal, setShowHideLodgeModal] = useState(false);
	const [leavePage, setLeavePage] = useState('');
	const [path, setPath] = useState(false);
	const [showHideUnsaved, setShowHideUnsaved] = useState(false);
	const toggle = () => {};

	let history = useHistory();

	useEffect(() => {
		//if input search has value show search dropdown
		if (searchInputValue) {
			setLeavePage(1);
			if (searchInputValue.length > 3) {
				searchUserData();
			} else {
				setDropdownOpen(false);
			}
		} else {
			setLeavePage('');
		}
		// eslint-disable-next-line
	}, [searchInputValue]);

	function searchUserData() {
		setSearchOrgData([]);
		setMemberData([]);
		setShowHideLoader(true);
		setShowResult(false);
		/* API for user search endpoint */
		setDropdownOpen(false);
		copiriPortalRequest('/users/search?input=' + searchInputValue, config.app_version, 'get')
			.then(response => {
				if (response.data.users.length > 0) {
					setMemberData(response.data.users);
				} else {
					setMemberData([]);
				}
			})
			.catch(error => {});
		var timeStamp = Math.floor(Date.now() / 1000);
		copiriPortalRequest(
			'/organizations/searches/name?input=' + searchInputValue + '&source=name&stamp=' + timeStamp,
			config.app_version,
			'get'
		)
			.then(response => {
				if (response.data.organizations.length > 0) {
					setSearchOrgData(response.data.organizations);
				} else {
					setSearchOrgData([]);
				}
				setShowResult(true);
				setShowHideLoader(false);
				setDropdownOpen(true);
				/*setTimeout(
        () => setDropdownOpen(true), 
        500
      );*/
			})
			.catch(error => {
				setSearchOrgData([]);
				setShowResult(true);
				setShowHideLoader(false);
				setDropdownOpen(true);
				/*setTimeout(
        () => setDropdownOpen(true), 
        500
      );*/
			});
	}

	function serachUser(e) {
		if (e.key === 'Enter') {
			setSearchInputValue(e.target.value);
			searchUserData();
		}
	}

	const closeDropDown = e => {
		setSearchInputValue('');
		setDropdownOpen(false);
		setLeavePage('');
	};

	const closeModal = () => {
		setShowHide(!showHide);
		setLeavePage('');
	};
	function showModal(id) {
		setDropdownOpen(false);
		setProfileId(id);
		setShowHide(!showHide);
		setLeavePage('');
		setSearchInputValue('');
	}

	function getNameEmail(name, param) {
		const answer_array = name.split('(');
		if (param === 'name') {
			return answer_array[0];
		} else {
			if (answer_array[1]) {
				return answer_array[1].replace(')', '');
			} else {
				return '';
			}
		}
	}

	//openLodgeModal
	const openLodgeModal = id => {
		setSelectedOrgName(document.getElementById('org_name_' + id).innerText);
		setId(id);
		setShowHideLodgeModal(!showHideLodgeModal);
		setDropdownOpen(false);
		setLeavePage('');
		setSearchInputValue('');
	};

	const closeLodgeModal = () => {
		setShowHideLodgeModal(!showHideLodgeModal);
		setLeavePage('');
	};

	const blockedNavigation = nLocation => {
		//nLocation gives the next location object
		setPath(nLocation['pathname']);
		if (leavePage) {
			setLeavePage(0);
			setShowHideUnsaved(!showHideUnsaved);
		}
		//required to block navigation
		return false;
	};

	const updateUnsavedModal = index => {
		setShowHideUnsaved(!showHideUnsaved);
		history.push(path);
		setSearchInputValue('');
		setDropdownOpen(false);
		setLeavePage('');
	};

	const closeModalUnsaved = () => {
		setLeavePage(1);
		setShowHideUnsaved(!showHideUnsaved);
	};
	return (
		<>
			{leavePage ? <Prompt message={blockedNavigation} /> : ''}
			<Dropdown isOpen={dropdownOpen} toggle={toggle} className='search-box'>
				<DropdownToggle tag='div' data-toggle='dropdown' aria-expanded={dropdownOpen}>
					<Input
						type='search'
						placeholder='Search...'
						aria-label='Search'
						className='rounded-pill search-input'
						value={searchInputValue}
						onChange={({ target }) => setSearchInputValue(target.value)}
						onKeyPress={serachUser}
					/>
					<i className='far fa-search position-absolute text-400 search-box-icon'></i>
					{searchInputValue && (
						<button className='close' onClick={closeDropDown}>
							<i className='far fa-times'></i>
							{showHideLoader ? (
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
									style={{ marginLeft: '1px', position: 'absolute', marginTop: '-9px' }}
								/>
							) : (
								''
							)}
						</button>
					)}
				</DropdownToggle>
				<DropdownMenu>
					<div className='scrollbar py-3' style={{ maxHeight: '24rem' }}>
						<DropdownItem className='px-card pt-0 pb-2' header>
							Users
						</DropdownItem>
						{memberData.length > 0 &&
							memberData.map((item, i) => (
								<DropdownItem className='px-card py-2' key={item.id}>
									<Media className='align-item-center' onClick={() => showModal(item.id)}>
										{item.image ? (
											<Avatar src={item.image} size='l' className='rounded-circle ' />
										) : (
											<Avatar src={avtar_img} size='l' className='rounded-circle ' />
										)}
										<Media body className='ml-2'>
											<h6 className='mb-0' style={{ fontSize: '11px' }}>
												{getNameEmail(item.name, 'name')}
											</h6>
											<p className='fs--2 mb-0'>{getNameEmail(item.name, 'email')} </p>
										</Media>
									</Media>
								</DropdownItem>
							))}

						{memberData.length <= 0 && showResult && (
							<DropdownItem className='px-card pt-0 pb-2' header>
								No User Found
							</DropdownItem>
						)}

						<hr className='border-200' />
						<DropdownItem className='px-card pt-0 pb-2' header>
							Bodies
						</DropdownItem>

						{searchOrgData.length > 0 &&
							searchOrgData.map((item, i) => (
								<DropdownItem className='px-card py-2' key={item.org.id}>
									<Media
										className='align-item-center'
										onClick={() => openLodgeModal(item.org.id)}
										style={{ alignItems: 'center' }}
									>
										<Avatar src={org_img} size='l' className='rounded-circle ' />
										<Media body className='ml-2'>
											<h6
												className='mb-0'
												id={'org_name_' + item.org.id}
												style={{ fontSize: '11px', whiteSpace: 'initial' }}
											>
												{item.org.name}
											</h6>
											<p className='fs--2 mb-0'>{item.address}</p>
										</Media>
									</Media>
								</DropdownItem>
							))}
						{searchOrgData.length <= 0 && showResult && (
							<DropdownItem className='px-card pt-0 pb-2' header>
								No Organization Found
							</DropdownItem>
						)}
					</div>
				</DropdownMenu>
			</Dropdown>
			<UserProfileModal showHide={showHide} onClick={closeModal} profileId={profileId} />
			<LodgeModal
				showHide={showHideLodgeModal}
				onClick={closeLodgeModal}
				updateModal={closeLodgeModal}
				id={id}
				selectedOrgName={selectedOrgName}
			/>

			<UnsavedDataModal
				showHide={showHideUnsaved}
				onClick={closeModalUnsaved}
				path={path}
				updateModal={updateUnsavedModal}
			/>
		</>
	);
};

export default UserSearchBox;
