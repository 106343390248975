import React from 'react';
import PropTypes from 'prop-types';
import { cssTransition } from 'react-toastify';

export const Fade = cssTransition({ enter: 'fadeIn', exit: 'fadeOut' });

export const CloseButton = ({ closeToast }) => (
	<i className='fas fa-times my-2 fs--2' style={{ opacity: 0.5 }} onClick={closeToast}></i>
);

CloseButton.propTypes = { closeToast: PropTypes.func };
