// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse, Row, Col } from 'reactstrap'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import qr_code from '../../../assets/img/logos/qr_code.jpg'; // eslint-disable-next-line
import { copy } from '../Common'; // eslint-disable-next-line
import UserMemberShipInformationChildAccordion from './UserMemberShipInformationChildAccordion'; // eslint-disable-next-line
import MemberShipInformationModal from './MemberShipInformationModal';
import { copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
const UserMemberShipInformationAccordionItem = ({
	data,
	active,
	onToggle,
	currentIndex,
	pageLanguageContent,
	profileId
}) => {
	// eslint-disable-next-line
	const { question, showChild, id, number, name, role, seal, status_id, is_admin, checked, parent_id } = data;
	const [clicked, setClicked] = useState('0'); // eslint-disable-next-line
	const [showHideModal, setShowHideModal] = useState(false); // eslint-disable-next-line
	const [type, setType] = useState('');
	const [modalHeadingText, setModalHeadingText] = useState('Edit Membership Number'); // eslint-disable-next-line
	const handleChildToggle = index => {
		if (clicked === index) {
			return setClicked('0');
		}
		setClicked(index);
	};

	const showHideMemebrShipInforamtionModal = type => {
		if (type === 'memberShipNumber') {
			setModalHeadingText(pageLanguageContent['PRL118'] ? pageLanguageContent['PRL118'] : '');
		}
		if (type === 'memberShipStatus') {
			setModalHeadingText(pageLanguageContent['PRL119'] ? pageLanguageContent['PRL119'] : '');
		}

		if (type === 'memberShipOffice') {
			setModalHeadingText(pageLanguageContent['PRL120'] ? pageLanguageContent['PRL120'] : '');
		}
		if (type === 'history') {
			setModalHeadingText(pageLanguageContent['PRL121'] ? pageLanguageContent['PRL121'] : '');
		}

		setShowHideModal(true);
		setType(type);
	};
	const closeAllModals = () => {
		setShowHideModal(false);
	};

	const handleChangeAdminstrator = e => {
		const target = e.target;
		var sendFormData = {};
		if (target.value === 'active') {
			sendFormData['is_admin'] = true;
		} else {
			sendFormData['is_admin'] = false;
		}

		copiriPortalRequest('/users/' + profileId + '/memberships/' + data['id'], config.app_version, 'patch', sendFormData)
			.then(response => {
				toast.success('Success');
			})
			.catch(error => {
				toast.error(error.response.result);
			});
	};

	return (
		<>
			<Card className={'shadow-none border rounded-0 ' + (parent_id ? 'orgChildMember' : '')}>
				<CardHeader
					onClick={onToggle}
					className={`py-2 px-lg-3 px-0 border-none cursor-pointer ${
						active ? 'activeMemebrShipHeader' : 'deActiveMemebrShipHeader'
					}`}
				>
					<span className='font-weight-medium text-dark text-sans-serif pl-2'>{name}</span>
					<div className='openHideAccordian'>
						<span className={checked ? 'headerText' : ''}>
							{checked ? (
								<>
									{' '}
									{pageLanguageContent['PRL122'] ? pageLanguageContent['PRL122'] : ''}: {checked}{' '}
								</>
							) : (
								''
							)}
						</span>
						{/*<span className="trashIcon"><i className="far fa-trash-alt"></i></span>*/}
						<span className='updownArrow'>
							{active ? <i className='far fa-angle-up'></i> : <i className='far fa-angle-down'></i>}
						</span>
					</div>
				</CardHeader>
				<Collapse isOpen={active}>
					<CardBody className='pt-3'>
						<div className='profile-card-membership'>
							<Row>
								<Col lg={6} className='pc-ms-border-right'>
									<ul>
										<li className='d-flex mb-2'>
											<h4>
												{pageLanguageContent['PRL123'] ? pageLanguageContent['PRL123'] : ''}: <span>{number}</span>
											</h4>
											<div className='ml-auto'>
												<div className='membership-icon'>
													<Badge
														color={'soft-info'}
														className='mr-1'
														onClick={() => showHideMemebrShipInforamtionModal('memberShipNumber')}
														data-toggle='tooltip'
														title='Edit'
													>
														<i className='far fa-edit'></i>
													</Badge>
													{/*{ number && 
                      <Badge color={'soft-info'}  className="bg-trash" >
                        <i className="far fa-trash-alt"></i>
                      </Badge>
                    }*/}
												</div>
											</div>
										</li>
										<li className='d-flex'>
											<h4>
												{pageLanguageContent['PRL124'] ? pageLanguageContent['PRL124'] : ''}:{' '}
												<span>{role['roleName']}</span>
											</h4>
											<div className='ml-auto'>
												<div className='membership-icon'>
													<Badge
														color={'soft-info'}
														className='mr-1'
														onClick={() => showHideMemebrShipInforamtionModal('memberShipOffice')}
														data-toggle='tooltip'
														title='Edit'
													>
														<i className='far fa-edit'></i>
													</Badge>
													<Badge color={'soft-info'} className='mr-1' data-toggle='tooltip' title='View'>
														<i className='fas fa-eye' onClick={() => showHideMemebrShipInforamtionModal('history')}></i>
													</Badge>
												</div>
											</div>
										</li>
									</ul>
								</Col>
								<Col lg={6}>
									<ul className='right-side'>
										<li className='d-flex mb-2'>
											<h4>
												{pageLanguageContent['PRL125'] ? pageLanguageContent['PRL125'] : ''}:{' '}
												<span>Good Standling</span>
											</h4>
											<div className='ml-auto'>
												<div className='membership-icon'>
													<Badge
														color={'soft-info'}
														className=''
														onClick={() => showHideMemebrShipInforamtionModal('memberShipStatus')}
														data-toggle='tooltip'
														title='Edit'
													>
														<i className='far fa-edit'></i>
													</Badge>
												</div>
											</div>
										</li>
										<li className='d-flex'>
											<h4>{pageLanguageContent['PRL126'] ? pageLanguageContent['PRL126'] : ''} ?</h4>
											<div className='memebrShipDiv'>
												<span className='mr-2 ml-3'>
													<input
														type='radio'
														id='radio1'
														name={'btnradio-' + id}
														className='mr-1 memebrAdmin'
														defaultChecked={is_admin ? true : false}
														value='active'
														onChange={handleChangeAdminstrator}
													/>
													<label htmlFor='radio1'>
														{pageLanguageContent['PRL038'] ? pageLanguageContent['PRL038'] : ''}
													</label>
												</span>
												<span className='mr-2'>
													<input
														type='radio'
														id='radio2'
														name={'btnradio-' + id}
														className='mr-1 memebrAdmin'
														defaultChecked={!is_admin ? true : false}
														value='in_active'
														onChange={handleChangeAdminstrator}
													/>
													<label htmlFor='radio2'>
														{pageLanguageContent['PRL039'] ? pageLanguageContent['PRL039'] : ''}
													</label>
												</span>
											</div>
										</li>
									</ul>
								</Col>
							</Row>
						</div>
					</CardBody>
					{/*{ showChild && <UserMemberShipInformationChildAccordion active={clicked === currentIndex} key={currentIndex+100} question={question} data ={data} onToggle={() => handleChildToggle(currentIndex)} currentIndex={currentIndex+1} showHideMemebrShipInforamtionModal={showHideMemebrShipInforamtionModal}
        /> }*/}
				</Collapse>
				<MemberShipInformationModal
					showHide={showHideModal}
					onClick={closeAllModals}
					type={type}
					modalHeadingText={modalHeadingText}
					pageLanguageContent={pageLanguageContent}
					membershipData={data}
					profileId={profileId}
				/>
			</Card>
		</>
	);
};

export default UserMemberShipInformationAccordionItem;
