import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

const CopiriLoader = props => (
	<Row className='flex-center py-5'>
		<Col xs='auto'>
			<Spinner {...props} />
		</Col>
	</Row>
);

CopiriLoader.propTypes = { ...Spinner.propTypes };

CopiriLoader.defaultProps = {
	type: 'grow',
	size: 'lg',
	color: 'primary'
};

export default CopiriLoader;
