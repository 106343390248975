/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import config from '../config/config';
import { copiriAxiosPost } from '../api/api';
import { withRouter } from 'react-router-dom'; // eslint-disable-next-line
import { Redirect } from 'react-router-dom';
class OrgDropDown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			country: '30169',
			admin_dropdown_data: [],
			is_login: true,
			showHideLoader: false
		};
	}
	changeSelectBox = event => {
		this.setState({ country: event.target.value });
		this.setState({
			showHideLoader: true
		});
		const formdata = new FormData();
		formdata.append('action', 'admin_gl');
		formdata.append('gl', event.target.value);
		copiriAxiosPost(config.portal_url, formdata)
			.then(response => {
				localStorage.setItem('selected_org_id', response.data.session_org);
				this.setState({
					showHideLoader: false
				});
				window.location.reload(false);
			})
			.catch(error => {});
	};
	componentDidMount() {
		const formdata = new FormData();
		formdata.append('action', 'admin_dropdown');
		copiriAxiosPost(config.portal_url, formdata)
			.then(response => {
				if (response.data.length > 0) {
					localStorage.setItem('archon', true);
				} else {
					localStorage.setItem('archon', false);
				}

				this.setState({
					admin_dropdown_data: response.data
				});
				var i;
				for (i = 0; i < response.data.length; i++) {
					// eslint-disable-next-line
					if (response.data[i]['selected'] == 'true') {
						const val = response.data[i]['value'];
						localStorage.setItem('selected_org_id', val);
						this.setState({
							country: response.data[i]['value']
						});
					}
				}
			})
			.catch(error => {});
	}
	render() {
		// eslint-disable-next-line
		const { admin_dropdown_data, is_login, country, showHideLoader } = this.state;
		if (admin_dropdown_data.length > 0) {
			return (
				<>
					{showHideLoader ? <div className='loader'></div> : ''}
					<div className='navbar-vertical-divider'>
						<hr className='navbar-vertical-hr my-2' />
					</div>
					<li className='nav-item'>
						<div className='d-flex align-items-center'>
							<span className='nav-link-icon'>
								<i className='fad fa-map ml-1' transform='shrink-2'></i>
							</span>
							<select
								className='form-control'
								value={country}
								onChange={this.changeSelectBox}
								name='admin_dropdown'
								style={{
									width: '150px',
									marginLeft: '0',
									color: '#5e6e82',
									fontSize: '.875rem',
									fontWeight: '500',
									padding: '0'
								}}
							>
								{admin_dropdown_data.length > 0 &&
									admin_dropdown_data.map((item, i) => (
										<option value={item.value} key={i} id={item.value} className='nav-link-text'>
											{item.text}
										</option>
									))}
							</select>
						</div>
					</li>
				</>
			);
		} else {
			return <></>;
		}
	}
}

export default withRouter(OrgDropDown);
