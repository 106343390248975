/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader'; // eslint-disable-next-line
import default_avatar from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
const UserProfileCardSection = ({ data, pageLanguageContent }) => {
	return (
		<div className='alert alert-info' role='alert'>
			<div className='cardText'>{pageLanguageContent['PRL075'] ? pageLanguageContent['PRL075'] : ''} </div>
			<div className='cardText' style={{ marginTop: '3px' }}>
				{pageLanguageContent['PRL076'] ? pageLanguageContent['PRL076'] : ''}
				<a target='_blank' rel='noopener noreferrer' href='https://welcome.amity.copiri.com'>
					{' '}
					<strong>https://welcome.amity.copiri.com </strong>
				</a>
			</div>
		</div>
	);
};
export default UserProfileCardSection;
