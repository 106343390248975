// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse, Row, Col } from 'reactstrap';
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import qr_code from '../../assets/img/logos/qr_code.jpg'; // eslint-disable-next-line
import { copy } from './Common';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
const AccordionItem = ({ data, active, onToggle, currentIndex, pageLanguageContent, profileId }) => {
	var checkData = JSON.parse(localStorage.getItem('idiom'));
	var errorContent = checkData?.ERR;
	// eslint-disable-next-line
	const { type, url, locked, notify, expires, code, activated, assigned, issued, location, number, secret } = data;
	function copyContent(content) {
		copy(content);
	}

	const handleChange = (event, name) => {
		var formData = {};
		if (event.target.checked) {
			formData[name] = true;
		} else {
			formData[name] = false;
		}
		var cardId = data['id'];
		udpateCardData(cardId,formData);		
	};

	const handleChangeActivateData = (event, name) => {
		var formData = {};
		let date = new Date();
		var dateString = date.getUTCFullYear() +"-"+ (date.getUTCMonth()+1) +"-"+ date.getUTCDate();
		formData[name] = dateString;
		var cardId = data['id'];
		udpateCardData(cardId,formData);		
	};

	function udpateCardData(cardId,formData) {
		copiriPortalRequest('/cards/' + cardId, config.app_version, 'patch', formData)
			.then(response => {
				toast.success(pageLanguageContent['PRL143']?pageLanguageContent['PRL143']:'');
			})
			.catch(error => {
				toast.error(errorContent['ERR005']?errorContent['ERR005']:'');
			});
	}


	function getFormattedDate(date) {
		var formatDate = '';
		if (date) {
			const today = new Date(date);
			const options = { month: 'long', day: 'numeric', year: 'numeric' };
			formatDate = new Intl.DateTimeFormat('en-US', options).format(today);
		}
		return formatDate;
	}
	return (
		<Card className='shadow-none border rounded-0'>
			<CardHeader
				onClick={onToggle}
				className={`py-2 border-none cursor-pointer ${active ? 'activeMemebrShipHeader' : 'deActiveMemebrShipHeader'}`}
			>
				<span className='font-weight-medium text-dark text-sans-serif pl-2'>
					{type} {number ? <>(...{number ? number.toString().slice(-5) : ''})</> : ''}
				</span>
				<div className='openHideAccordian'>
					{' '}
					{active ? <i className='fas fa-minus'></i> : <i className='fas fa-plus'></i>}
				</div>
			</CardHeader>
			<Collapse isOpen={active}>
				<CardBody className='pt-2'>
					<div className='profile-card-info'>
						<Row>
							<Col lg={9} className='px-2'>
								<ul className='acc-tab-details-list'>
									<li>
										<h6>{pageLanguageContent['PRL129'] ? pageLanguageContent['PRL129'] : ''}:</h6>
										<span>
											{' '}
											{number ? number.slice(0, 2) : ''} {number ? number.slice(2, 5) : ''}{' '}
											{number ? number.slice(5, 9) : ''}
										</span>
									</li>
									<li>
										<h6>{pageLanguageContent['PRL130'] ? pageLanguageContent['PRL130'] : ''}:</h6>
										<a href={url} target='_blank' rel='noopener noreferrer'>
											{url.slice(0, 50)}
										</a>
										<Badge
											color={'soft-info'}
											className='userProfileModalCopy'
											onClick={() => copyContent(url)}
											data-toggle='tooltip'
											title='Copy'
										>
											<i className='far fa-copy'></i>
										</Badge>
									</li>
									<li>
										<h6>{pageLanguageContent['PRL131'] ? pageLanguageContent['PRL131'] : ''}:</h6>
										<span className='mr-2'>
											<input
												type='checkbox'
												id='check1'
												className='mr-1'
												defaultChecked={locked}
												onChange={e => handleChange(e, 'user_locked')}
											/>{' '}
											<label htmlFor='check1'>
												{pageLanguageContent['PRL132'] ? pageLanguageContent['PRL132'] : ''}
											</label>
										</span>
										<span>
											<input
												type='checkbox'
												id='check2'
												className='mr-1'
												defaultChecked={notify}
												onChange={e => handleChange(e, 'notify_on_scan')}
											/>{' '}
											<label htmlFor='check2'>
												{pageLanguageContent['PRL133'] ? pageLanguageContent['PRL133'] : ''}
											</label>
										</span>
									</li>
									<li>
										<h6>{pageLanguageContent['PRL134'] ? pageLanguageContent['PRL134'] : ''}:</h6>
										<span>{secret ? secret.match(new RegExp('.{1,4}', 'g')).join('-') : ''}</span>
									</li>
								</ul>
								<Row className='ms-card-bottom'>
									<Col lg={4} className='px-0'>
										<h6>{pageLanguageContent['PRL135'] ? pageLanguageContent['PRL135'] : ''}</h6>
										<span className='mr-2'>
											<input
												type='radio'
												id='radio11'
												defaultChecked={!activated ? true : false}
												name='btnradio'
												className='mr-1'
												onChange={e => handleChangeActivateData(e, 'activated')}
											/>{' '}
											<label htmlFor='radio11'>{pageLanguageContent['PRL127'] ? pageLanguageContent['PRL127'] : ''}</label>
										</span>
										<span className='mr-2'>
											<input
												type='radio'
												id='radio12'
												defaultChecked={activated ? true : false}
												name='btnradio'
												className='mr-1'
												onChange={e => handleChangeActivateData(e, 'deactivated')}
											/>{' '}
											<label htmlFor='radio12'>{pageLanguageContent['PRL128'] ? pageLanguageContent['PRL128'] : ''}</label>
										</span>
									</Col>
									<Col lg={4} className='border-lr px-lg-3 px-0'>
										<h6>{pageLanguageContent['PRL136'] ? pageLanguageContent['PRL136'] : ''}</h6>
										<span>{getFormattedDate(assigned)}</span>
									</Col>
									<Col lg={4} className='pr-0 pl-lg-3 pl-0'>
										<h6>{pageLanguageContent['PRL137'] ? pageLanguageContent['PRL137'] : ''}</h6>
										<span>{getFormattedDate(expires)}</span>
									</Col>
								</Row>
							</Col>
							<Col lg={3} className='px-2 d-flex justify-content-end'>
								<div className='qr-code'>
									<a href={url} target='_blank' rel='noopener noreferrer'>
										<img src={qr_code} alt='QrCode' />
										<p>{pageLanguageContent['PRL138'] ? pageLanguageContent['PRL138'] : ''}</p>
									</a>
								</div>
							</Col>
						</Row>
					</div>
				</CardBody>
			</Collapse>
		</Card>
	);
};

export default AccordionItem;
