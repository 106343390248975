import React, { useState, useEffect, lazy, Suspense } from 'react'; 
import { Row, Col, Button, CustomInput } from 'reactstrap'; 
import { copiriBankingPortalRequest, getCurrencyAmount, getCurrencySymbol } from '../api/copiriBankingApi'; 
import config from '../config/config';
import CopiriLoader from '../common/CopiriLoader';
import './style.css';
import paymentMethod from './paymentMethod';
import { toast } from 'react-toastify';
import ActivateAccount from './ActivateAccount'; 
import loader_image from '../../assets/img/loader/small_loader.gif'; 
const StripePaymentForm = lazy(() => import('./StripePaymentForm'));
const UserPayment = props => {
	// eslint-disable-next-line
	const { searchData, backToMemebrListing, publicPaymentPageContent, selectedLanguageId, mode } = props;
	const [showCheckout, setShowCheckout] = useState(true);
	const [paymentData, setPaymentData] = useState([]);
	const [totalAmount, setTotalAmount] = useState('');
	const [processingFee, setProcessingFee] = useState('');
	const [showStripePaymentForm, setShowStripePaymentForm] = useState(false);
	const [selectedPaymentType, setSelectedPaymentType] = useState('');
	const [disableSUbmitButton, setDisableSUbmitButton] = useState(true);
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	const [liveModeClientSecret, setLiveModeClientSecret] = useState('');
	const [updateEmail, setUpdateEmail] = useState(false);
	const [hasEmail, setHasEmail] = useState(false);
	const [userEmail, setUserEmail] = useState(''); // eslint-disable-next-line
	const [recurring, setRecurring] = useState(true); // eslint-disable-next-line
	const [disableSubmitEmailButton, setDisableSubmitEmailButton] = useState(false); // eslint-disable-next-line
	const [enableActivateAccountClick, setEnableActivateAccountClick] = useState(false);
	const [cardSpinner, setCardSpinner] = useState(false);
	const [showHideUdpatedEmail, setShowHideUdpatedEmail] = useState(false);
	const [showHideUpdatedAccount, setShowHideUpdatedAccount] = useState(false);
	const [successbutton, setSuccessbutton] = useState(false);
	/* set and get variable for input data */
	useEffect(() => {
		if (searchData && searchData.key) {
			getPaymentPageTranslationText(selectedLanguageId, searchData.key);			
			localStorage.setItem('lineItemData',JSON.stringify(searchData.lines));
			if (searchData.lines.length>0) {
				for(let i=0;i<searchData.lines.length;i++) {
					if (searchData.lines[i]['editable']) {
						searchData.lines[i]['amount'] = getCurrencyAmount(searchData.lines[i]['amount'],searchData.lines[i]['currency'],true)
					}
				}
			}
			setPaymentData(searchData.lines); // set line data on payment form
			var sum = searchData.lines.reduce((n, { amount }) => n + parseFloat(amount), 0);
			setTotalAmount(sum);
			if (searchData?.email && searchData?.account) {
				setUserEmail(searchData.email);
				setUpdateEmail(false);
				setHasEmail(true);
			} else {
				setDisableSUbmitButton(false);
				setUpdateEmail(true);
				if (searchData?.email && !searchData?.account) {
					setDisableSUbmitButton(true);
					setHasEmail(true);
					setUserEmail(searchData?.email);
				} else {
					setDisableSUbmitButton(false);
					setHasEmail(false);
					setUserEmail('');
				}
			}
		}
		window.onbeforeunload = function() {
	        return true;
	    };
	    return () => {
	        window.onbeforeunload = null;
	    };
		// eslint-disable-next-line
	}, []);

	/*function to get the language data */
	function getPaymentPageTranslationText(lang_id, token) {
		storePaymentTranslatedText(lang_id, token);
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData?.APY?.PMT) {
			setPageLanguageContent(checkData.APY.PMT);
			setShowCheckout(false);
		}
	}

	/* function to get/set the translated text in local storage*/
	function storePaymentTranslatedText(lang_id, token) {
		copiriBankingPortalRequest('/languages/' + lang_id + '/package/APY', config.app_version, 'get', '', token)
			.then(response => {
				setShowCheckout(false);
				if (response.data.result.PMT) {
					setPageLanguageContent(response.data.result.PMT);
					var storeTranslatedText = {};
					storeTranslatedText['APY'] = response.data.result;
					localStorage.setItem('idiom', JSON.stringify(storeTranslatedText));
				}
			})
			.catch(error => {
				setShowCheckout(false);
			});
	}

	/*function to select payment method and calculate the proceesing fee */
	function selectPaymentMethod(method) {
		setSelectedPaymentType(method);
		var url =
			'/payments/fees/calculate?currency=' +
			searchData.currency +
			'&type=' +
			method +
			'&amount=' +
			totalAmount +
			'&organization_id=' +
			searchData.organization_id;
		setDisableSUbmitButton(true);
		setCardSpinner(true);
		copiriBankingPortalRequest(url, config.app_version, 'get', '', searchData.key)
			.then(response => {
				setCardSpinner(false);
				if (response && response.data && response.data.fee) {
					setProcessingFee(response.data.fee);
					setDisableSUbmitButton(false);
				}
			})
			.catch(error => {
				setCardSpinner(false);
				setSelectedPaymentType('');
			});
	}

	/* function to show user payemnt listing when click on button on page 3*/
	function backToPaymentListing() {
		if (!showHideUdpatedEmail || !showHideUpdatedAccount) {
			setUpdateEmail(true);
			if (showHideUdpatedEmail) {
				setHasEmail(true);
				setUserEmail(userEmail);
			} else {
				setHasEmail(false);
				setUserEmail('');
			}
		}
		setShowStripePaymentForm(false);
		setDisableSUbmitButton(false);
	}

	/* function to show the stripe checkout form */
	function showPaymentForm() {
		if (selectedPaymentType === '') {
			alert(pageLanguageContent['PMT017']);
		} else {
			setLiveModeClientSecret('');
			if (mode && mode === 'live') {
				setDisableSUbmitButton(true);
				/* if have real user. A new payment intent will generated for live stripe */
				var sendFormData = {};
				sendFormData['amount'] = totalAmount;
				sendFormData['currency'] = searchData?.currency;
				sendFormData['fee'] = processingFee;
				sendFormData['method'] = selectedPaymentType;
				sendFormData['organization_id'] = searchData?.organization_id;
				sendFormData['user_id'] = searchData?.user_id;
				if (searchData?.show_recurring && recurring) {
					sendFormData['recurring'] = recurring;
				}
				var lineData = [];
				if (paymentData && paymentData.length > 0) {
					for (var i = 0; i < paymentData.length; i++) {
						var arr = {};
						arr[paymentData[i]['id']] = parseFloat(paymentData[i]['amount']);
						lineData.push(arr);
					}
				}
				sendFormData['lines'] = lineData;
				copiriBankingPortalRequest('/payments/onestep/dues', config.app_version, 'post', sendFormData, searchData.key)
					.then(response => {
						setDisableSUbmitButton(false);
						if (response.data.paymentIntent) {
							setLiveModeClientSecret(response.data.paymentIntent); //if have client secret set it and show stripe form
							setShowStripePaymentForm(true);
							window.onbeforeunload = null;
						} else {
							toast.error(pageLanguageContent['PMT010']);
						}						
					})
					.catch(error => {
						setDisableSUbmitButton(false);
					});
			} else {
				setShowStripePaymentForm(true);
				window.onbeforeunload = null;
				setShowHideUdpatedEmail(true);
				setShowHideUpdatedAccount(true);
			}
			localStorage.setItem('ahepa_line_items_data', JSON.stringify(paymentData));
			localStorage.setItem('ahepa_search_data', JSON.stringify(searchData));
			localStorage.setItem('ahepa_total_amount', totalAmount);
			localStorage.setItem('ahepa_processing_fee_amount', parseFloat(processingFee));
			//setShowStripePaymentForm(true);
		}
	}

	const handleFocus = event => event.target.select();

	/* function to handle input change */
	const handleInputChange = (e, index) => {
		//const patt = /^\d+\.{0,1}\d{0,3}$/;
		const patt = new RegExp(/^\d*\.?\d*$/);
		let value = e.target.value;
		if (e.target.value === '') {
			value = 0;
		}
		if (!patt.test(value)) {
			return false;
		}
		let newFormValues = [...paymentData];
		newFormValues[index]['amount'] = value;
		newFormValues.map(item => item.amount);
		setPaymentData(newFormValues);
		var totalAmountSum = 0;
		if (paymentData.length > 0) {
			for (var i = 0; i < paymentData.length; i++) {
				totalAmountSum += parseFloat(paymentData[i]['amount']);
			}
		}
		setSelectedPaymentType('');
		if (!updateEmail) {
			setDisableSUbmitButton(true);
		}
		//setDisableSUbmitButton(true);
		setTotalAmount(totalAmountSum);
		localStorage.removeItem('ahepa_line_items_data');
		localStorage.removeItem('ahepa_search_data');
		localStorage.removeItem('ahepa_total_amount');
	};
	
	const changePaymentData = (e, index) => {
		let newFormValues = [...paymentData];
		if (newFormValues.length>0) {
			for(let i=0;i<newFormValues.length;i++) {
				if (newFormValues[i]['editable']) {
					newFormValues[i]['amount'] = getCurrencyAmount(newFormValues[i]['amount'],newFormValues[i]['currency'],true)
				}
			}
		}
		setPaymentData(newFormValues);
	}

	/* function to update email activate the account */
	// eslint-disable-next-line
	function activateAccount() {
		if (updateEmail) {
			setDisableSubmitEmailButton(true);
			if (selectedPaymentType !== '') {
				setDisableSUbmitButton(true);
			}
			setEnableActivateAccountClick(true);
			setTimeout(() => {
				setEnableActivateAccountClick(false);
			}, 500);			
			//setSuccessbutton(true);
			setTimeout(() => {
				setSuccessbutton(false);
			}, 5000);

		} else {
			showPaymentForm();
		}
	}

	function callbackEnableDisableButton(updatedEmail) {
		setDisableSubmitEmailButton(false);
		setDisableSUbmitButton(false);
	}

	/*call back function when email and account activation api success and call to dues payment API*/
	function callbackToDuesPayment(updatedEmail, updateEmailDone = false, updateActiveAccountDone = false) {
		if (updatedEmail) {
			if (!hasEmail) {
				setUserEmail(updatedEmail.substring(0, 5) + '...');
			}
			setHasEmail(true);
		}
		setUpdateEmail(false);
		setShowHideUdpatedEmail(updateEmailDone);
		setShowHideUpdatedAccount(updateActiveAccountDone);
		if (selectedPaymentType === '') {
			setSuccessbutton(true);
			setDisableSUbmitButton(true);
		} else {
			showPaymentForm();
		}
	}

	function enableDisableSubmitButton(showhide) {
		if (hasEmail) {
			if (showhide ==='enable') {
				if (selectedPaymentType==='' && hasEmail) {
					setDisableSUbmitButton(false);
				}
			} else {
				if (showhide ==='disable') {
					if (selectedPaymentType==='') {
						setDisableSUbmitButton(true);
					}
				}
			}
		}
	}

	/* function to reset line items form */
	// eslint-disable-next-line
	const resetLineItemForm = () => {
		let lineItemData = JSON.parse(localStorage.getItem('lineItemData'))	
		setPaymentData(lineItemData);
		var totalAmountSum = 0;
		if (lineItemData.length > 0) {
			for (var i = 0; i < lineItemData.length; i++) {
				totalAmountSum += parseFloat(lineItemData[i]['amount']);
			}
		}
		setSelectedPaymentType('');
		setTotalAmount(totalAmountSum);		
		if (!updateEmail) {
			setDisableSUbmitButton(true);
		}
		setRecurring(true);
		localStorage.removeItem('ahepa_line_items_data');
		localStorage.removeItem('ahepa_search_data');
		localStorage.removeItem('ahepa_total_amount');
	}

	return (
		<>
			{showCheckout ? (
				<CopiriLoader />
			) : (
				<div className='flex-center pt-2 mb-pading '>
					{!showStripePaymentForm ? (
						<div className='stripe_payment'>
							<div className='payment_div'>
								<Row>
									<Col xs='8'>
										<h4>{pageLanguageContent['PMT012'] ? pageLanguageContent['PMT012'] : ''}</h4>
									</Col>
									<Col xs='4' className='text-right'>
										<span className='amount'>{searchData?.currency}</span>
									</Col>
									<Col xs='12'>
										<div className='amount'>{searchData?.overview}</div>
									</Col>
								</Row>
								<Row className=''>
									<Col xs='12'>
										<hr />
									</Col>
									<Col xs='12' className='member-info member-info-full-width'>
										<span className='member-info-left'>
											<strong>{searchData?.name}</strong>
										</span>
										<span className='member-info-right'>
											<strong>
												{pageLanguageContent['PMT013'] ? pageLanguageContent['PMT013'] : ''} {searchData?.number}
											</strong>
										</span>
									</Col>
									<Col xs='12'>
										<hr />
									</Col>
								</Row>
								{paymentData.length > 0 &&
									paymentData.map((item, i) => (
										<Row className='mb-2 align-items-center' key={i}>
											<Col l xs='8'>
												<h6 className='mb-0'>{item.description}</h6>
											</Col> 
											<Col xs='4' className={`text-right ${(getCurrencySymbol(searchData?.currency).length>2 && item.editable )  ? ' d-flex' : ' '} `}>
												{item.editable ? (
													<>
														{ getCurrencySymbol(searchData?.currency).length>2  ?
															<span className='currency_symbol currency_text'>{getCurrencySymbol(searchData?.currency)}</span>
														:
															<span className='currency_symbol'>{getCurrencySymbol(searchData?.currency)}</span>
														}
														<input
															type='text'
															name={item.description}
															value={item.amount?item.amount:''}
															className='form-control amount'
															onChange={e => handleInputChange(e, i)}
															onFocus={handleFocus}
															onBlur={changePaymentData}
															placeholder="0.00"
															maxlength="10"
														/>
													</>
												) : (
													<span className='amount'>{getCurrencyAmount(item.amount, searchData.currency)}</span>
												)}
											</Col>
										</Row>
									))}
								{paymentData.length > 0 && (
									<Row>
										<Col xs='12' className='mb-1'>
											<hr />
										</Col>
									</Row>
								)}
								<Row className='mb-1 align-items-center'>
									<Col xs='5' className='member-info'>
										<h6 className='mb-0'>{pageLanguageContent['PMT014'] ? pageLanguageContent['PMT014'] : ''}</h6>
									</Col>
									<Col xs='7' className='text-right member-info'>
										<h6 className='mb-0'>{getCurrencyAmount(totalAmount, searchData.currency)}</h6>
									</Col>
									{searchData?.notes && (
										<>
											<Col xs='12'>
												<h6 className='mb-1 mt-2'>
													{pageLanguageContent['PMT015'] ? pageLanguageContent['PMT015'] : ''}
												</h6>
											</Col>
											<Col xs='12'>
												<div className='amount mb-2'>{searchData?.notes}</div>
											</Col>
										</>
									)}
									<Col xs='12'>
										<hr />
									</Col>
								</Row>
								{/* If the request has payment options, section starts */}
								{paymentData && paymentData.length > 0 && (
									<>
										{searchData && searchData.methods && searchData.methods.length > 0 ? (
											<>
												<Row className='mb-2'>
													<div className='bank_type_inner_div'>
														{searchData &&
															searchData.methods &&
															searchData.methods.length > 0 &&
															searchData.methods.map((item, i) => (
																<Col
																	xs='2'
																	className={'bank_type ' + (selectedPaymentType === item ? 'selected' : '')}
																	onClick={() => selectPaymentMethod(item)}
																	key={i}
																>
																	{cardSpinner && selectedPaymentType === item ? (
																		<img
																			className='rounded-circle cardSpinnerImage'
																			src={loader_image}
																			alt='img'
																			width='42px'
																		/>
																	) : (
																		<>
																			<i className='fas fa-credit-card iconType'></i>
																			<p className='payment_type_criteria mb-0'>{paymentMethod[item]}</p>
																		</>
																	)}
																</Col>
															))}
													</div>
												</Row>
												{paymentData.length > 0 && (
													<Row className='mb-2 align-items-center'>
														<Col xs='7'>
															<h6 className='mb-0'>
																{pageLanguageContent['PMT016'] ? pageLanguageContent['PMT016'] : ''}
															</h6>
														</Col>
														<Col xs='5' className='text-right'>
															{selectedPaymentType ? (
																<>
																	{cardSpinner ? (
																		<img
																			className='rounded-circle cardSpinnerProcessingImage'
																			src={loader_image}
																			alt='img'
																			width='42px'
																		/>
																	) : (
																		<h6 className='mb-0'>{getCurrencyAmount(processingFee, searchData.currency)}</h6>
																	)}
																</>
															) : (
																<h6 className='amount mb-0' style={{ fontSize: '.8333rem', fontWeight: '400' }}>
																	{pageLanguageContent['PMT017'] ? pageLanguageContent['PMT017'] : ''}
																</h6>
															)}
														</Col>
													</Row>
												)}
												<Row className='totalAmount badge-soft-success mb-2'>
													<Col xs='7'>
														<span>
															<strong>{pageLanguageContent['PMT014'] ? pageLanguageContent['PMT014'] : ''}</strong>
														</span>
													</Col>

													<Col xs='5' className='text-right pr-2'>
														{selectedPaymentType && (
															<>
																{paymentData.length > 0 ? (
																	<span style={{ fontSize: '.8333rem' }}>
																		{cardSpinner ? (
																			<></>
																		) : (
																			<strong>
																				{getCurrencyAmount(totalAmount + processingFee, searchData.currency)}
																			</strong>
																		)}
																	</span>
																) : (
																	<span style={{ fontSize: '.8333rem' }}>
																		{cardSpinner ? (
																			<></>
																		) : (
																			<strong>{getCurrencyAmount(totalAmount, searchData.currency)}</strong>
																		)}
																	</span>
																)}
															</>
														)}
													</Col>
												</Row>

												{searchData?.show_recurring && (
													<Row className='mb-2 align-items-center'>
														<Col xs='8'>
															<h6 className='mb-0'>
																{pageLanguageContent['PMT040'] ? pageLanguageContent['PMT040'] : ''}
															</h6>
														</Col>
														<Col xs='4' className='text-right'>
															<CustomInput
																id='show-experiences'
																onChange={() => setRecurring(!recurring)}
																checked={recurring}
																type='checkbox'
															/>
														</Col>
														<Col xs='12'>
															<div className='important-notes'>
																{pageLanguageContent['PMT041'] ? pageLanguageContent['PMT041'] : ''}
															</div>
														</Col>
													</Row>
												)}


												<Row className='mb-0 align-items-center'>
													<Col xs='12' className='text-center'> 
														<h6 className="mb-0 mt-2" style={{color:'lightgray',cursor:'pointer'}} onClick={() => resetLineItemForm()}>{pageLanguageContent['PMT063'] ? pageLanguageContent['PMT063'] : ''}</h6>
													</Col>
												</Row>
											</>
										) : (
											<></>
										)}
										{/* If the request has payment options, section ends */}
									</>
								)}
							</div>
							{paymentData && paymentData.length > 0 && (
								<div className='payment_type_div mb-2'>
									{searchData && searchData.methods && searchData.methods.length > 0 ? (
										<>
											{updateEmail ? (
												<ActivateAccount
													enableActivateAccountClick={enableActivateAccountClick}
													pageLanguageContent={pageLanguageContent}
													publicPaymentPageContent={publicPaymentPageContent}
													hasEmail={hasEmail}
													searchData={searchData}
													userEmail={userEmail}
													callbackToDuesPayment={callbackToDuesPayment}
													callbackEnableDisableButton={callbackEnableDisableButton}
													selectedPaymentType={selectedPaymentType}
													enableDisableSubmitButton={enableDisableSubmitButton}
												/>
											) : (
												<Row className='mb-3 align-items-center'>
													{/* Section for user's email starts */}
													<Col xs='7'>
														<h6 className='mb-0'>
															{pageLanguageContent['PMT034'] ? pageLanguageContent['PMT034'] : ''}
														</h6>
													</Col>
													<Col xs='5' className='text-right'>
														<span style={{ fontSize: '0.8333rem', fontWeight: '600' }} className="userName">
															{userEmail ? userEmail : ''}
														</span>
													</Col>
													<Col xs='12'>
														<div className='important-notes'>
															{pageLanguageContent['PMT035'] ? pageLanguageContent['PMT035'] : ''}{' '}
															<a href='mailto:support@copiri.com'>
																{pageLanguageContent['PMT036'] ? pageLanguageContent['PMT036'] : ''}
															</a>
															.
														</div>
													</Col>
													{/* Section for user's email ends */}
												</Row>
											)}	

											{
												showHideUpdatedAccount && (
													<Row className='mb-3 align-items-center'>
														<Col xs='12'>
															<h6 className='mb-1'>{pageLanguageContent['PMT048'] ? pageLanguageContent['PMT048'] : ''}</h6>
														</Col>
														<Col xs='12'>
															<div className='important-notes'>
																{pageLanguageContent['PMT066'] ? pageLanguageContent['PMT066'] : ''}
															</div>
														</Col>
													</Row>
												)
											}										

											{/* Section for Continue to next page or back to previous page starts */}
											<Row style={{marginTop:'1.5rem'}}>
												<Col xs='5' className='text-center mb-2'>
													<Button className='continue w-100 py-2 mt-2' color='secondary' onClick={backToMemebrListing}>
														<span id='button-text'>
															{pageLanguageContent['PMT018'] ? pageLanguageContent['PMT018'] : ''}
														</span>
													</Button>
												</Col>
												<Col xs='7' className='text-center mb-2'>
													<Button
														id='submit'
														className='continue w-100 py-2 mt-2'
														color={successbutton ? 'success' : 'primary'}
														onClick={activateAccount}
														disabled={disableSUbmitButton}
														data-toggle='tooltip'
														title={
															disableSUbmitButton
																? pageLanguageContent['PMT039']
																	? pageLanguageContent['PMT039']
																	: ''
																: ''
														}
													>
														<span id='button-text'>
															{publicPaymentPageContent['WLC005'] ? publicPaymentPageContent['WLC005'] : ''}
														</span>
													</Button>
												</Col>
											</Row>
											{!updateEmail && (
												<Row>
													<Col xs='12'>
														<div className='amount important-notes'>
															<b>{pageLanguageContent['PMT019'] ? pageLanguageContent['PMT019'] : ''}</b>{' '}
															{pageLanguageContent['PMT020'] ? pageLanguageContent['PMT020'] : ''}
															<a
																href='https://amity.copiri.com/terms'
																target='_blank'
																rel='noopener noreferrer'
																style={{ marginLeft: '2px' }}
															>
																{pageLanguageContent['PMT021'] ? pageLanguageContent['PMT021'] : ''}
															</a>
															.
														</div>
													</Col>
												</Row>
											)}
											{/* Section for Continue to next page or back to previous page ends */}
										</>
									) : (
										<Row>
											{/* If there are no payment options */}
											<Col xs='12' style={{ fontSize: '0.9em', color: 'red', textAlign: 'center', fontWeight: '600' }}>
												{pageLanguageContent['PMT022'] ? pageLanguageContent['PMT022'] : ''}
											</Col>
										</Row>
									)}
								</div>
							)}
						</div>
					) : (

						<>
						{
							(config.stripe_publisher_key || searchData?.persistentKey) ?
								<Suspense fallback='Loading'>
									<StripePaymentForm
										paymentData={paymentData}
										totalAmount={totalAmount}
										selectedPaymentType={selectedPaymentType}
										searchData={searchData}
										sumTotalAmount={totalAmount + processingFee}
										backToMemebrListing={backToPaymentListing}
										pageLanguageContent={pageLanguageContent}
										mode={mode}
										liveModeClientSecret={liveModeClientSecret}
									/>
								</Suspense>
							:
							<div className='stripe_payment'>
								<h6>{pageLanguageContent['PMT023'] ? pageLanguageContent['PMT023'] : ''}</h6>
								<Button className='continue w-100 py-2 mt-2' color='secondary' onClick={backToPaymentListing}>
									<span id='button-text'>{pageLanguageContent['PMT018'] ? pageLanguageContent['PMT018'] : ''}</span>
								</Button>
							</div>
						}
						</>
					)}
				</div>
			)}
		</>
	);
};
export default UserPayment;
