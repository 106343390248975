/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, checkValidation } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const EmailField = props => {
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props;
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		email: ''
	});
	const [validation, setValidation] = useState({
		error_email: ''
	});

	useEffect(() => {
		if (props.id) {
			const { updateEmail } = lodgeData;
			var email = '';
			if (updateEmail && updateEmail !== undefined) {
				email = updateEmail;
			} else email = '';
			setInputField({ ...inputField, email });
		}
		// eslint-disable-next-line
	}, [props.id]);

	/* get the input values */
	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	/* function to update the email on lodge modal */
	function updateData() {
		let errors = { ...validation };
		var errorCount = 0;
		var checkError = checkValidation(inputField['email'], 'email');
		if (!inputField.email.trim()) {
			errors.error_email = pageLanguageContent['CLD071'];
			errorCount++;
		} else if (!checkError) {
			errors.error_email = pageLanguageContent['CLD073'];
			errorCount++;
		} else {
			errors.error_email = '';
		}
		setValidation(errors);
		if (errorCount <= 0) {
			if (checkDataChange) {
				var sendFormData = {};
				var sendData = {};
				sendFormData['email'] = inputField['email'];
				sendData['value'] = sendFormData;
				setShowLoader(true);
				copiriPortalRequest('/organizations/' + id + '/email', config.app_version, 'patch', sendData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							closeModal();
							updateLodgeModal('email', inputField['email']);
						}
					})
					.catch(error => {
						toast.error(error.response.data.result);
						setShowLoader(false);
					});
			}
		}
	}

	return (
		<Row>
			<Col lg={12}>
				<FormGroupInput
					id='email'
					label={pageLanguageContent['CLD035'] ? pageLanguageContent['CLD035'] : ''}
					placeholder=''
					type='text'
					name='email'
					onChange={inputsHandler}
					value={inputField.email}
				/>
				{validation.error_email && <p className='formValidationError'>{validation.error_email}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default EmailField;
