/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
// eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
const LodgeHistoryModal = props => {
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	const [historyData, setHistoryData] = useState([]);
	const [historyType, setHistoryType] = useState('');
	const [checkRequest, setCheckRequest] = useState(false);
	useEffect(() => {
		if (props.showHideHistoryModal) {
			setHistoryData([]);
			setHistoryType(props.historyText);
			getHistoryData(props.lodgeData['id'], props.historyText);
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			if (checkData['CLD']) {
				setPageLanguageContent(checkData['CLD']);
			}
		}
		// eslint-disable-next-line
	}, [props.showHideHistoryModal]);

	/* API to get the history data */

	function getHistoryData(id, type) {
		setCheckRequest(false);
		setHistoryData([]);
		copiriPortalRequest('/organizations/' + id + '/' + type + '/history', config.app_version, 'get')
			.then(response => {
				setCheckRequest(true);
				if (response.data.history.length > 0) {
					setHistoryData(response.data.history);
				}
			})
			.catch(error => {
				setCheckRequest(true);
			});
	}

	/* function to update the content column */
	function getContent(item) {
		var content = 'N/A';
		if (historyType === 'email') {
			content = item['email'];
		}

		if (historyType === 'website') {
			content = item['url'];
		}

		if (historyType === 'address') {
			content = item['city'] + ',' + item['state'] + ',' + item['post_code'];
		}
		return content;
	}

	function getFormattedDate(date) {
		var formatDate = '';
		if (date) {
			// eslint-disable-next-line
			const today = new Date(date);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			formatDate = new Intl.DateTimeFormat('en-US', options).format(today);
		}
		return formatDate;
	}

	return (
		<div>
			<Modal
				isOpen={props.showHideHistoryModal}
				toggle={props.closeHistoryModal}
				id='historyModal'
				size='lg'
				className='historyModal'
			>
				<ModalHeader toggle={props.closeHistoryModal}>
					{pageLanguageContent['CLD044'] ? pageLanguageContent['CLD044'] : ''}
				</ModalHeader>
				<ModalBody>
					<Table striped bordered className='table-sm fs--1 mb-0 table-dashboard-th-nowrap'>
						<thead className='thead_desktop'>
							<tr className='bg-200 text-900 '>
								<th style={{ textAlign: 'left' }}>
									{pageLanguageContent['CLD045'] ? pageLanguageContent['CLD045'] : ''}
								</th>
								<th style={{ textAlign: 'left' }}>
									{pageLanguageContent['CLD046'] ? pageLanguageContent['CLD046'] : ''}
								</th>
								<th style={{ textAlign: 'left' }}>
									{pageLanguageContent['CLD047'] ? pageLanguageContent['CLD047'] : ''}
								</th>
								<th style={{ textAlign: 'left' }}>Action</th>
							</tr>
						</thead>
						<tbody id='histroyModalTableBody'>
							{historyData &&
								historyData.length > 0 &&
								historyData.map((item, index) => (
									<tr className='btn-reveal-trigger historyRow'>
										<td>{item['creator'] ? getFormattedDate(item['creator']['created']) : ''}</td>
										<td>{getContent(item)}</td>
										<td>
											{item.creator['name'] ? (
												<>
													<i className='far fa-user-circle'></i> <span>{item.creator['name']}</span>
												</>
											) : (
												<>{pageLanguageContent['CLD132'] ? pageLanguageContent['CLD132'] : ''}</>
											)}
										</td>
										<td>
											<Badge color={'soft-danger'} className='imgDel-icon'>
												<i className='fas fa-trash-alt'></i>
											</Badge>
										</td>
									</tr>
								))}

							{checkRequest && historyData.length <= 0 && (
								<tr className='btn-reveal-trigger historyRow'>
									<td colSpan='4' align='center'>
										{pageLanguageContent['CLD049'] ? pageLanguageContent['CLD049'] : ''}
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={props.closeHistoryModal}>
						{pageLanguageContent['CLD042'] ? pageLanguageContent['CLD042'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
export default LodgeHistoryModal;
