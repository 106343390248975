import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import AppContext from '../../context/Context';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem'; // eslint-disable-next-line
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config';// eslint-disable-next-line
import site_permission from '../config/site_permission';
const NavbarVerticalMenu = ({ routes, location, langContetn }) => {
	const [openedIndex, setOpenedIndex] = useState(null);
	const { setShowBurgerMenu } = useContext(AppContext);
	const [pageLanguageContent, setPageLanguageContent] = useState([]); // eslint-disable-next-line
	const [showHideLoader, setShowHideLoader] = useState(false);
	useEffect(() => {
		getLanguagePack();
		let openedDropdown = null;
		routes.forEach((route, index) => {
			if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
		});

		setOpenedIndex(openedDropdown);
		// eslint-disable-next-line
	}, [langContetn]);

	const toggleOpened = (e, index) => {
		e.preventDefault();
		return setOpenedIndex(openedIndex === index ? null : index);
	};

	const toggleOpenedMenu = (e, index) => {
		setShowBurgerMenu(false);
		return setOpenedIndex(openedIndex === index ? null : index);
	};

	function getLanguagePack() {
		if (langContetn && langContetn.MNU) {
			setPageLanguageContent(langContetn.MNU);
		}
	}

	const checkRoute = name => {
		if (name === 'MNU005') {
			var checkStat = localStorage.getItem('stat');
			// eslint-disable-next-line
			if (checkStat && checkStat == 1) {
				return 'alert-danger';
			} else {
				return '';
			}
		}

		if (name === 'MNU003') {
			var checkStatLastCert = localStorage.getItem('stat_last_cert');
			// eslint-disable-next-line
			if (checkStatLastCert == -1 || checkStatLastCert > 270) {
				return 'alert-danger';
			} else if (checkStatLastCert > 180) {
				return 'alert-warning';
			}
		}
	};

	return routes.map((route, index) => {
		if (!route.children) {		
				return (
					<div key={index}>
						{!showHideLoader && (
							<Fragment key={index}>
								<NavItem>
									<NavLink
										className='nav-link'
										{...route}
										onClick={e => toggleOpenedMenu(e, index)}
										id={checkRoute(route.menuitem)}
									>
										<NavbarVerticalMenuItem route={route} pageLanguageContent={pageLanguageContent} />
									</NavLink>
								</NavItem>
								{route.divider && route.divider !== 'false' && (
									<div className='navbar-vertical-divider'>
										<hr className='navbar-vertical-hr my-2' />
									</div>
								)}
							</Fragment>
						)}
					</div>
				);
			
		}
		return (
			<div key={index}>
				{!showHideLoader && (
					<Fragment key={index}>
						{route.divider && route.divider !== 'false' && (
							<div className='navbar-vertical-divider'>
								<hr className='navbar-vertical-hr my-2' />
							</div>
						)}
						<NavItem>
							<BootstrapNavLink
								onClick={e => toggleOpened(e, index)}
								className='dropdown-indicator cursor-pointer'
								aria-expanded={openedIndex === index}
							>
								<NavbarVerticalMenuItem route={route} pageLanguageContent={pageLanguageContent} />
							</BootstrapNavLink>
							<Collapse isOpen={openedIndex === index} key={index + 100}>
								<Nav>
									<NavbarVerticalMenu routes={route.children} location={location} />
								</Nav>
							</Collapse>
						</NavItem>
					</Fragment>
				)}
			</div>
		);
	});

};

NavbarVerticalMenu.propTypes = {
	//routes: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
