import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import routes from '../../routes';
import Flex from '../common/Flex';
import Logo from './Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton'; // eslint-disable-next-line
import { Redirect } from 'react-router-dom';
import CookieBanner from '../cookie-baaner/CookieBanner';
import OrgDropDown from './OrgDropDown';
import { useHistory } from 'react-router-dom';
import {
	checkSiteUrlAccess
} from '../api/api'; // eslint-disable-next-line
import site_permission from '../config/site_permission';
const NavbarVertical = ({ navbarStyle, langContetn }) => {
	const navBarRef = useRef(null);
	const history = useHistory();
	var checkSession = localStorage.getItem('palaver');
	localStorage.setItem('last_url_location', history.location.pathname); // eslint-disable-next-line
	const {
		showBurgerMenu,
		isNavbarVerticalCollapsed,
		setIsNavbarVerticalCollapsed,
		isCombo,
		setShowBurgerMenu,
		setNavbarCollapsed
	} = useContext(AppContext);

	const HTMLClassList = document.getElementsByTagName('html')[0].classList;
	//Control Component did mount and unmounted of hover effect
	if (isNavbarVerticalCollapsed) {
		HTMLClassList.add('navbar-vertical-collapsed');
	}
	var checksiteAccessUrl = checkSiteUrlAccess(); //for check which site user is accessing
	useEffect(() => {
		if (is.windows()) {
			HTMLClassList.add('windows');
		}
		if (is.chrome()) {
			HTMLClassList.add('chrome');
		}
		if (is.firefox()) {
			HTMLClassList.add('firefox');
		}
		return () => {
			HTMLClassList.remove('navbar-vertical-collapsed-hover');
		};
	}, [isNavbarVerticalCollapsed, HTMLClassList, langContetn]);

	//Control mouseEnter event
	let time = null;
	const handleMouseEnter = () => {
		if (isNavbarVerticalCollapsed) {
			time = setTimeout(() => {
				HTMLClassList.add('navbar-vertical-collapsed-hover');
			}, 100);
		}
	};
	if (!checkSession) {
		if (checksiteAccessUrl && checksiteAccessUrl ==='payments') {
		}  else {
			return <Redirect to='/authentication/basic/login' />;
		}
	}
	return (
		<Navbar
			expand={navbarBreakPoint}
			className={classNames('navbar-vertical navbar-glass', {
				[`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
			})}
			light
		>
			<Flex align='center'>
				<ToggleButton
					isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
					setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
				/>
				<Logo at='navbar-vertical' width={40} />
			</Flex>

			<Collapse
				navbar
				isOpen={showBurgerMenu}
				className='scrollbar'
				innerRef={navBarRef}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={() => {
					clearTimeout(time);
					HTMLClassList.remove('navbar-vertical-collapsed-hover');
				}}
				style={
					navbarStyle === 'vibrant' && {
						backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
					}
				}
			>
				<Nav navbar vertical>
					<NavbarVerticalMenu routes={routes} location={''} langContetn={langContetn} />
					<CookieBanner />
					<OrgDropDown />
				</Nav>
				<div className='settings px-3 px-xl-0'>
					{isCombo && (
						<div className={`d-${topNavbarBreakpoint}-none`}>
							<div className='navbar-vertical-divider'>
								<hr className='navbar-vertical-hr my-2' />
							</div>
							<Nav navbar>
								<NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
							</Nav>
						</div>
					)}
					<div className='navbar-vertical-divider'>
						<p />
					</div>
				</div>
			</Collapse>
		</Navbar>
	);
};

NavbarVertical.protoTypes = {
	navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
	navbarStyle: 'transparent'
};

export default NavbarVertical;
