import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import PaymentLayout from './PaymentLayout';
const PayLayout = (props) => {
	useEffect(() => {
	}, []);

	return (
		<Router fallback={<span />}>
			<Switch>
				<Route component={PaymentLayout} />
			</Switch>
			<ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
		</Router>
	);
};

export default PayLayout;
