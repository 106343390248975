import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/illustrations/amity_logo.png';
import banking_logo from '../../assets/img/illustrations/copiri_logo.png';
import { checkSiteUrlAccess } from '../api/api'; // eslint-disable-next-line
const Logo = ({ at, width, className, ...rest }) => {
	var checkSiteAccess = checkSiteUrlAccess(); //for check if the user accessing banking url
	return (
		<Link
			to='/'
			className={classNames(
				'text-decoration-none',
				{ 'navbar-brand text-left': at === 'navbar-vertical' },
				{ 'navbar-brand text-left': at === 'navbar-top' }
			)}
			{...rest}
		>
			<div
				className={classNames(
					'd-flex',
					{
						'align-items-center py-3': at === 'navbar-vertical',
						'align-items-center': at === 'navbar-top',
						'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth'
					},
					className
				)}
			>
				{checkSiteAccess && checkSiteAccess ==='portal' ? (
					<img className='mr-2' src={logo} alt='Logo' width='100px' />
				)
				:
					<img className='mr-2' src={banking_logo} alt='Logo' width='100px' />
				}
			</div>
		</Link>
	);
};

Logo.propTypes = {
	at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
	width: PropTypes.number,
	className: PropTypes.string
};

Logo.defaultProps = { at: 'auth', width: 58 };

export default Logo;
