import axios from 'axios';
import config from '../config/config'; // eslint-disable-next-line

export const copiriBankingPortalRequest = async (url, app_version, method, data = '',token='') => {
	if (data !== '') {
		if (token && token!=='') {
			return await axios({
				method: method,
				url: config.api_url + '/v3' + url,
				data: data,
				withCredentials: true,
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json'
					//'Content-Type' : 'application/x-www-form-urlencoded',
				}
			});

		} else {
			return await axios({
				method: method,
				url: config.api_url + '/v3' + url,
				data: data,
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json'
					//'Content-Type' : 'application/x-www-form-urlencoded',
				}
			});
		}
	} else if (token && token!=='') {
		return await axios({
			method: method,
			withCredentials: true,
			url: config.api_url + '/v3' + url,
			headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json'
			}
		});
	} else {
		return await axios({
			method: method,
			withCredentials: true,
			url: config.api_url + '/v3' + url,
			headers: {
				'Content-Type': 'application/json'
			}
		});
	}
};

export function getCurrencyAmount(amount, currency, currencyWithoutSymbol = false, format = "EN-us")
{
    // Default the return amount
    var returnAmount = '';
    if (currencyWithoutSymbol) {
    	if (amount >0) {
	    	returnAmount = new Intl.NumberFormat('EN-us', { 
		        style: 'currency',
		        currency: currency, 
		        currencyDisplay: "code" ,
		      })
		      .format(amount)
		      .replace(currency, "")
		      .trim()
		      .replace(/,/g, "");
		    } else {
		    	returnAmount = amount;
		    }
    } else {
    	returnAmount = new Intl.NumberFormat(
        format,
        {
          style                     : 'currency',
          currency                  : currency,
          minimumFractionDigits     : 2,
          maximumFractionDigits     : 2,
        }
    	).format(amount);
    }

    return returnAmount;
}


export function getCurrencySymbol (currency, locale="EN-us") {
  return (0).toLocaleString(
    locale,
    {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  ).replace(/\d/g, '').trim()
}
