import React, { useState, useEffect } from 'react';
import { Row, Col, Button, NavLink } from 'reactstrap'; 
import './style.css'; 
import HeaderSection from './HeaderSection';
import { getCurrencyAmount } from '../api/copiriBankingApi'; 
import { copiriBankingPortalRequest } from '../api/copiriBankingApi'; 
import config from '../config/config'; 
import { Link } from 'react-router-dom';
const PaymentSuccess = props => {
	// eslint-disable-next-line
	const [showSuccessForm, setShowSuccessForm] = useState(false);
	const [configVariableData, setConfigVariableData] = useState([]);
	const [pageLanguageContent, setPageLanguageContent] = useState([]);
	var paymentData = JSON.parse(localStorage.getItem('ahepa_line_items_data'));
	var searchData = JSON.parse(localStorage.getItem('ahepa_search_data'));
	var processingFee = parseFloat(localStorage.getItem('ahepa_processing_fee_amount'));
	var sum = 0;
	if (paymentData && paymentData.length > 0) {
		for (var i = 0; i < paymentData.length; i++) {
			sum += parseFloat(paymentData[i]['amount']);
		}
	}
	useEffect(() => {
		var siteConfig = JSON.parse(localStorage.getItem('site_config_variables'));
		setConfigVariableData(siteConfig);
		const url = new URL(window.location);
		const clientSecret = url.searchParams.get('payment_intent_client_secret');
		if (!clientSecret) {
			var redirectURl = window.location.protocol + '//' + window.location.host + '/';
			window.location.href = redirectURl;
		} else {
			getLanguagePack(siteConfig);
			setShowSuccessForm(true);
			setTimeout(() => {
				window.history.pushState(null, '', '/success/payment');
			}, 5000);
		}
		// eslint-disable-next-line
	}, []);

	/*function to get the language data */
	function getLanguagePack(siteConfig) {
		var lang_id = 177394503; // set to defult if no language id selected as ture
		if (siteConfig && siteConfig.dialects && siteConfig.dialects.length > 0) {
			for (var i = 0; i < siteConfig.dialects.length; i++) {
				if (siteConfig.dialects[i]['selected'] === true) {
					lang_id = siteConfig.dialects[i]['id'];
				}
			}
		}
		const searchTokenData = JSON.parse(localStorage.getItem('ahepa_search_data'));
		var token = searchTokenData?.key;
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		if (checkData === null || checkData === undefined || checkData === '') {
			storePaymentTranslatedText(lang_id, token);
		} else {
			if (checkData?.APY?.PMT) {
				setPageLanguageContent(checkData.APY.PMT);
			} else {
				storePaymentTranslatedText(lang_id, token);
			}
		}
	}

	/* function to get/set the translated text in local storage*/
	function storePaymentTranslatedText(lang_id, token) {
		copiriBankingPortalRequest('/languages/' + lang_id + '/package/APY', config.app_version, 'get', '', token)
			.then(response => {
				if (response.data.result.PMT) {
					setPageLanguageContent(response.data.result.PMT);
					var storeTranslatedText = {};
					storeTranslatedText['APY'] = response.data.result;
					localStorage.setItem('idiom', JSON.stringify(storeTranslatedText));
				}
			})
			.catch(error => {});
	}

	return (
		<div className='stripe_payment_layout'>
			{showSuccessForm ? (
				<>
					<HeaderSection configVariableData={configVariableData} />
					<div className='flex-center pt-2 mb-pading '>
						<div className='stripe_payment'>
							<div className='payment_div'>
								<Row>
									<Col xs='12'>
										<h5 style={{ color: '#4a934a', textAlign: 'center' }}>
											{' '}
											{pageLanguageContent['PMT028'] ? pageLanguageContent['PMT028'] : ''}
										</h5>
									</Col>

									<Col xs='12'>
										<hr />
									</Col>
									<Col xs='12' className='member-info member-info-full-width'>
										<span className='member-info-left'>
											<strong>{pageLanguageContent['PMT030'] ? pageLanguageContent['PMT030'] : ''}</strong>
										</span>
										<span className='member-info-right'>
											<strong>{searchData?.confirmation}</strong>
										</span>
									</Col>
								</Row>
								<Row className=''>
									<Col xs='12'>
										<hr />
									</Col>
									<Col xs='12' className='member-info member-info-full-width'>
										<span className='member-info-left'>
											<strong>{searchData?.name}</strong>
										</span>
										<span className='member-info-right'>
											<strong>
												{pageLanguageContent['PMT013'] ? pageLanguageContent['PMT013'] : ''} {searchData?.number}
											</strong>
										</span>
									</Col>
									<Col xs='12'>
										<hr />
									</Col>
								</Row>
								{paymentData &&
									paymentData.length > 0 &&
									paymentData.map((item, i) => (
										<Row className='mb-2 align-items-center' key={i}>
											<Col xs='8'>
												<h6 className='mb-0'>{item.description}</h6>
											</Col>
											<Col xs='4' className='text-right'>
												<span className='amount'>{getCurrencyAmount(item.amount, searchData?.currency)}</span>
											</Col>
										</Row>
									))}
								<Row>
									<Col xs='12' className='mb-1'>
										<hr />
									</Col>
								</Row>
								<Row className='mb-2 align-items-center'>
									<Col xs='8' className='member-info'>
										<h6 className='mb-0'>{pageLanguageContent['PMT016'] ? pageLanguageContent['PMT016'] : ''}</h6>
									</Col>
									<Col xs='4' className='text-right member-info'>
										<span className='amount'>
											{' '}
											<strong>{getCurrencyAmount(processingFee, searchData?.currency)}</strong>
										</span>
									</Col>
								</Row>
								<Row className='mb-2 align-items-center'>
									<Col xs='8' className='member-info'>
										<h6 className='mb-0'>{pageLanguageContent['PMT014'] ? pageLanguageContent['PMT014'] : ''}</h6>
									</Col>
									<Col xs='4' className='text-right member-info'>
										<span className='amount'>
											{' '}
											<strong>{getCurrencyAmount(sum + processingFee, searchData?.currency)}</strong>
										</span>
									</Col>
									<Col xs='12'>
										<hr />
									</Col>
								</Row>

								<Row>
									<Col xs='6' className='text-center mb-2'>
										<NavLink tag={Link} to='/' style={{ padding: '0' }}>
											<Button className='continue w-100 py-2 mt-2' color='primary'>
												<span id='button-text'>
													{pageLanguageContent['PMT031'] ? pageLanguageContent['PMT031'] : ''}
												</span>
											</Button>
										</NavLink>
									</Col>
									<Col xs='6' className='text-center mb-2'>
										<a href='https://www.ahepa.org'>
											<Button id='submit' className='continue w-100 py-2 mt-2' color='secondary'>
												<span id='button-text'>
													{pageLanguageContent['PMT032'] ? pageLanguageContent['PMT032'] : ''}
												</span>
											</Button>
										</a>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	);
};
export default PaymentSuccess;
