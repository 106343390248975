/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import CopiriLoader from '../common/CopiriLoader';
const PaymentHistoryModal = ({ showHide, className, onClick, count, path, updateModal, pageLanguageContentData,recentTransactionId,modalItemData }) => {
	const [transactionData, setTransactionData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [total,setTotal] = useState(0);
	useEffect(() => {
		if (showHide) {
			// call function to get the data for recent transacton modal
			getTransactionData(recentTransactionId);
		}
		// eslint-disable-next-line
	}, [showHide]);


	/* function to call API to get the recent transaction data to populate the modal data */
	function getTransactionData(transactionId) {
		if (transactionId) {
			setTransactionData([]);
			setShowLoader(true);
			copiriPortalRequest('/sellers/' + transactionId + '/ledger', config.app_version, 'get')
			.then(response => {
				if (response.data.transactions && response.data.transactions.length>0) {
					setTransactionData(response.data.transactions);
					var sum = 0;
					Object.keys(response.data.transactions).forEach(key => sum += response.data.transactions[key].amount || 0);
					setTotal(sum);
				}
				setShowLoader(false);
			}).catch(error => {
				setShowLoader(false);
			});
		}
	}

	return (
		<div>
			<Modal isOpen={showHide} toggle={onClick} size='lg' id='payment_modal'>
			{	showLoader ? (
					<CopiriLoader />
					) : (
					<>
				<ModalHeader toggle={onClick}>{pageLanguageContentData['HME012'] ? pageLanguageContentData['HME012'] : ''}</ModalHeader>
				<ModalBody>
					<Row className=''>
						<Col lg={12} md={12} sm={12} >
							<h5 className='mb-4 p-3' style={{textAlign:'center',borderBottom:'1px dashed #ccc'}}>{pageLanguageContentData['HME013'] ? pageLanguageContentData['HME013'] : ''}</h5>
						</Col>					
					</Row>
					<div className="profile-card-membership">
								{transactionData &&
									transactionData.length > 0 &&
									transactionData.map((item, i) => (
									<Row className='mb-2' key={item.id}> 
										<Col xs={10}>
											<h6 className='mb-0'>{item.date}: {pageLanguageContentData['HME018'] ? pageLanguageContentData['HME018'] : ''}</h6>
											<span style={{color: 'darkgrey', fontStyle: 'italic'}}>{item.description}</span>
										</Col>
										<Col xs={2}>
											<h6>$ {item.amount.toFixed(2)}&nbsp;<i  style={{color: '#337AB7'}} className="fas fa-question-circle"></i></h6>
										</Col>
									</Row>
								))}
								{transactionData &&
									transactionData.length > 0 &&
									<>
										<Row className=''>
											<Col lg={12} md={12} sm={12} >
												<h5 className='mb-2 p-3' style={{textAlign:'center',borderBottom:'1px dashed #ccc'}}> </h5>
											</Col>					
										</Row>
										<Row className='mb-4'>
											<Col xs={10}>
												<h6 className='mb-0'><strong>{pageLanguageContentData['HME017'] ? pageLanguageContentData['HME017'] : ''}:</strong></h6>
											</Col>
											<Col xs={2}>
												<h6>$ {total.toFixed(2)}&nbsp;</h6>
											</Col>
										</Row>
									</>
								}
								{transactionData &&
									transactionData.length <= 0 &&
									<Row className='mb-4'>
										<Col xs={12}>
											<h6 className='mb-0' style={{textAlign:'center'}}><strong>{pageLanguageContentData['HME005'] ? pageLanguageContentData['HME005'] : ''}</strong></h6>
										</Col>										
									</Row>
								}
							</div>
				</ModalBody>
				</>
					)
				}
				<ModalFooter>
					<Button color='secondary' onClick={onClick}>
						{pageLanguageContentData['HME014'] ? pageLanguageContentData['HME014'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default PaymentHistoryModal;
