import React, { useState, useEffect } from 'react';
import { copiriBankingPortalRequest } from '../api/copiriBankingApi'; 
import config from '../config/config'; 
import CopiriLoader from '../common/CopiriLoader';
import { Row, Col, Button, Label, FormGroup } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput'; 
import UserPayment from './UserPayment';
import { toast } from 'react-toastify';
import Footer from '../footer/Footer';
import HeaderSection from './HeaderSection';
export default function CheckPaymentElement() {
	const [showHideDuePayment, setShowHideDuePayment] = useState(false); // eslint-disable-next-line
	const [showHideLoader, setShowHideDueLoader] = useState(false);
	const [chapterDropdownData, setChapterDropdownData] = useState([]);
	const [disableChapter, setDisableChapter] = useState(true);
	const [disableLastName, setDisableLastName] = useState(true);
	const [disableMemebrNumber, setDisableMemebrNumber] = useState(true);
	const [disableSubmitButton, setDisableSubmitButton] = useState(true);
	const [districtData, setDistrictData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [districtSelectText, setDistrictSelectText] = useState(true); // eslint-disable-next-line
	const [logoImage, setLogoImage] = useState('');
	const [configVariableData, setConfigVariableData] = useState([]);
	const [publicPaymentPageContent, setpublicPaymentPageContent] = useState([]);
	const [selectedLanguageId, setSelectedLanguageId] = useState('');
	const [mode, setMode] = useState('');
	/* set and get variable for input data */
	const [inputField, setInputField] = useState({
		district: '',
		last_name: '',
		chapter: '',
		member_number: ''
	});
	/* set and get varaible for input errors */
	const [validation, setValidation] = useState({
		error_district: '',
		error_chapter: '',
		error_last_name: '',
		error_member_number: ''
	});

	useEffect(() => {
		var siteConfig = JSON.parse(localStorage.getItem('site_config_variables'));
		setConfigVariableData(siteConfig);
		getLanguagePack(siteConfig);
		/* remove local storage data that is stroed to show data on confimation screen*/
		localStorage.removeItem('ahepa_line_items_data');
		localStorage.removeItem('ahepa_search_data');
		localStorage.removeItem('ahepa_total_amount');

		window.onbeforeunload = function() {
	        return true;
	    };
	    return () => {
	        window.onbeforeunload = null;
	    };
		// eslint-disable-next-line
	}, []);

	/*function to get the language data */
	function getLanguagePack(siteConfig) {
		setShowHideDueLoader(true);
		var lang_id = 177394503; // set to defult if no language id selected as ture
		if (siteConfig && siteConfig.dialects && siteConfig.dialects.length > 0) {
			for (var i = 0; i < siteConfig.dialects.length; i++) {
				if (siteConfig.dialects[i]['selected'] === true) {
					lang_id = siteConfig.dialects[i]['id'];
				}
			}
		}
		setSelectedLanguageId(lang_id);
		var checkData = JSON.parse(localStorage.getItem('idiom'));
		storePaymentTranslatedText(lang_id);
		if (checkData?.APY?.WLC) {
			setpublicPaymentPageContent(checkData.APY.WLC);
		}
	}

	/* function to get/set the translated text in local storage*/
	function storePaymentTranslatedText(lang_id) {
		copiriBankingPortalRequest('/languages/' + lang_id + '/package/APY/WLC', config.app_version, 'get')
			.then(response => {
				setShowHideDueLoader(false);
				if (response.data.result.WLC) {
					setpublicPaymentPageContent(response.data.result.WLC);
					var storeTranslatedText = {};
					storeTranslatedText['APY'] = response.data.result;
					localStorage.setItem('idiom', JSON.stringify(storeTranslatedText));
				}
				getDistrictListData();
			})
			.catch(error => {
				setShowHideDueLoader(false);
			});
	}

	/*function to call api to get the listing of district dropdown */
	const getDistrictListData = () => {
		setDistrictData([]);
		copiriBankingPortalRequest('/ahepa/district', config.app_version, 'get')
			.then(response => {
				if (response && response.data && response.data.length > 0) {
					setDistrictData(response.data);
					setDistrictSelectText(true);
				} else {
					setDistrictSelectText(false);
				}
			})
			.catch(error => {
				disableItems('district');
				setDistrictSelectText(false);
			});
	};

	/* function to set the input field data */
	const inputsHandler = name => e => {
		if (e.target.value === '') {
			setDisableSubmitButton(true);
			disableItems(name);
			//setInputField({ ...inputField, [e.target.name]: e.target.value });
		} else {
			if (name === 'district') {
				setChapterDropdownData([]);
				var districkKey = e.target.value;
				copiriBankingPortalRequest('/ahepa/district/' + districkKey, config.app_version, 'get')
					.then(response => {
						if (response && response.data && response.data.length > 0) {
							setChapterDropdownData(response.data);
						}
						enableItems(name);
						emptyDropDownValues(districkKey);
					})
					.catch(error => {
						var district = '';
						var chapter = '';
						emptyDropDownValues(district, chapter);
					});
			} else {
				enableItems(name);
			}
			setInputField({ ...inputField, [e.target.name]: e.target.value });
		}
	};

	function emptyDropDownValues(district = '', chapter = '') {
		setInputField({ ...inputField, district, chapter });
	}

	/* enable input fields */
	function enableItems(name) {
		if (name === 'district') {
			setDisableChapter(false);
		} else if (name === 'chapter') {
			setDisableLastName(false);
		} else if (name === 'last_name') {
			setDisableMemebrNumber(false);
		} else if (name === 'member_number') {
			setDisableSubmitButton(false);
		} else {
			setDisableSubmitButton(false);
		}
	}

	/* disable input fields */
	function disableItems(name) {
		var chapter = '';
		var last_name = '';
		var member_number = '';
		var district = '';
		if (name === 'district') {
			setChapterDropdownData([]);
			setDisableChapter(true);
			setDisableLastName(true);
			setDisableMemebrNumber(true);
			setInputField({
				...inputField,
				district,
				chapter,
				last_name,
				member_number
			});
		} else if (name === 'chapter') {
			setDisableLastName(true);
			setDisableMemebrNumber(true);
			setInputField({ ...inputField, chapter, last_name, member_number });
		} else if (name === 'last_name') {
			setInputField({ ...inputField, last_name, member_number });
			setDisableMemebrNumber(true);
		} else if (name === 'member_number') {
			setInputField({ ...inputField, member_number });
			setDisableSubmitButton(true);
		} else {
		}
	}

	/* function when click on back button on second page */
	function backToMemebrListing() {
		setShowHideDuePayment(false);
	}

	/* submit input data and call search API */
	function submitData() {
		let errors = { ...validation };
		var errorCount = 0;
		if (!inputField.district.trim()) {
			errors.error_district = publicPaymentPageContent['WLC009'] ? publicPaymentPageContent['WLC009'] : '';
			errorCount++;
		} else {
			errors.error_district = '';
		}

		if (!inputField.chapter.trim()) {
			errors.error_chapter = publicPaymentPageContent['WLC009'] ? publicPaymentPageContent['WLC009'] : '';
			errorCount++;
		} else {
			errors.error_chapter = '';
		}

		if (!inputField.last_name.trim()) {
			errors.error_last_name = publicPaymentPageContent['WLC009'] ? publicPaymentPageContent['WLC009'] : '';
			errorCount++;
		} else {
			errors.error_last_name = '';
		}

		if (!inputField.member_number.trim()) {
			errors.error_member_number = publicPaymentPageContent['WLC009'] ? publicPaymentPageContent['WLC009'] : '';
			errorCount++;
		} else {
			errors.error_member_number = '';
		}
		setValidation(errors);
		if (errorCount <= 0) {
			setSearchData([]);
			setShowHideDuePayment(false);
			var sendFormData = {};
			sendFormData['district'] = inputField['district'];
			sendFormData['chapter'] = inputField['chapter'];
			sendFormData['name'] = inputField['last_name'];
			sendFormData['number'] = inputField['member_number'];
			setDisableSubmitButton(true);
			copiriBankingPortalRequest('/ahepa/search', config.app_version, 'post', sendFormData)
				.then(response => {
					if (response && response.data && response.data.key) {
						if (response.data.mode && response.data.mode === 'test') {
							setMode(response.data.mode); // for test workflow.
						} else {
							setMode('live'); //for live workflow
						}
						setSearchData(response.data);
						setShowHideDuePayment(true);
						setDisableSubmitButton(false);
					} else {
						setDisableSubmitButton(false);
						toast.error(publicPaymentPageContent['WLC010'] ? publicPaymentPageContent['WLC010'] : '');
					}
				})
				.catch(error => {
					setDisableSubmitButton(false);
				});
		}
	}

	return (
		<div className='stripe_payment_layout'>
			<HeaderSection configVariableData={configVariableData} />
			{showHideLoader ? (
				<CopiriLoader />
			) : (
				<>
					{!showHideDuePayment ? (
						<>
							<div className='flex-center pt-2 mb-pading '>
								<div className='stripe_payment'>
									<Row>
										<Col xs='12'>
											<h4>{publicPaymentPageContent['WLC012'] ? publicPaymentPageContent['WLC012'] : ''}</h4>
										</Col>

										{configVariableData && configVariableData?.title_text && configVariableData?.title_text.length > 0 && (
											<Col xs='12'>
												<div className='title_text'>
													{configVariableData?.title_text.map((item, i) => (
														<p key={i} className='mb-2'>
															{item}
														</p>
													))}
												</div>
											</Col>
										)}
									</Row>
									<Row>
										<Col lg={12}>
											<FormGroup>
												<Label for='lodgeCountry'>
													{publicPaymentPageContent['WLC001'] ? publicPaymentPageContent['WLC001'] : ''}
												</Label>
												<select
													id='district'
													label='Organization'
													type='text'
													onChange={inputsHandler('district')}
													value={inputField.district}
													name='district'
													className='form-control'
												>
													<option value=''>
														{districtSelectText
															? publicPaymentPageContent['WLC007']
																? publicPaymentPageContent['WLC007']
																: ''
															: publicPaymentPageContent['WLC006']
															? publicPaymentPageContent['WLC006']
															: ''}
													</option>
													{districtData.length > 0 &&
														districtData.map((item, i) => (
															<option value={item.id} key={item.id}>
																{item.name}
															</option>
														))}
												</select>
											</FormGroup>
											{validation.error_district && <p className='formValidationError'>{validation.error_district}</p>}
										</Col>
										<Col lg={12}>
											<FormGroup>
												<Label for='lodgeCountry'>
													{publicPaymentPageContent['WLC002'] ? publicPaymentPageContent['WLC002'] : ''}
												</Label>
												<select
													id='chpater'
													label='chapter'
													type='text'
													onChange={inputsHandler('chapter')}
													value={inputField.chapter}
													disabled={disableChapter}
													name='chapter'
													className='form-control'
												>
													<option value=''>
														{publicPaymentPageContent['WLC008'] ? publicPaymentPageContent['WLC008'] : ''}
													</option>
													chapterDropdownData
													{chapterDropdownData.length > 0 &&
														chapterDropdownData.map((item, i) => (
															<option value={item.id} key={item.id}>
																{item.name}
															</option>
														))}
												</select>
											</FormGroup>
											{validation.error_chapter && <p className='formValidationError'>{validation.error_chapter}</p>}
										</Col>

										<Col lg={12}>
											<FormGroupInput
												id='last_name'
												label={publicPaymentPageContent['WLC003'] ? publicPaymentPageContent['WLC003'] : ''}
												type='text'
												onChange={inputsHandler('last_name')}
												value={inputField.last_name}
												name='last_name'
												disabled={disableLastName}
											/>
											{validation.error_last_name && (
												<p className='formValidationError'>{validation.error_last_name}</p>
											)}
										</Col>
										<Col lg={12}>
											<FormGroupInput
												id='member_number'
												label={publicPaymentPageContent['WLC004'] ? publicPaymentPageContent['WLC004'] : ''}
												type='text'
												onChange={inputsHandler('member_number')}
												value={inputField.member_number}
												name='member_number'
												disabled={disableMemebrNumber}
											/>
											{validation.error_member_number && (
												<p className='formValidationError'>{validation.error_member_number}</p>
											)}
										</Col>
										<Col lg={12}>
											<Button
												color='primary'
												className='w-100 py-2 mt-3'
												onClick={submitData}
												disabled={disableSubmitButton}
											>
												{publicPaymentPageContent['WLC005'] ? publicPaymentPageContent['WLC005'] : ''}
											</Button>
											<p className='important-notes mt-3 text-center'>{publicPaymentPageContent['WLC013'] ? publicPaymentPageContent['WLC013'] : ''} <a href="https://banking.copiri.com" rel='noopener noreferrer' target='_blank'>{publicPaymentPageContent['WLC014'] ? publicPaymentPageContent['WLC014'] : ''}</a>.</p>
										</Col>
									</Row>
								</div>
							</div>
						</>
					) : (
						<UserPayment
							searchData={searchData}
							backToMemebrListing={backToMemebrListing}
							publicPaymentPageContent={publicPaymentPageContent}
							selectedLanguageId={selectedLanguageId}
							mode={mode}
						/>
					)}
				</>
			)}
			<Footer />
		</div>
	);
}
