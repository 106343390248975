// eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, getLanguagePackUrl } from './components/api/api'; // eslint-disable-next-line
import config from './components/config/config'; // eslint-disable-next-line
var checkData = JSON.parse(localStorage.getItem('idiom'));
var menuData = '';
var adminMenu = '';
if (checkData) {
	menuData = checkData.MNU ? checkData.MNU : '';
	adminMenu = checkData.AMU ? checkData.AMU : '';
}
export const grandLodgeRoutes = {
	name: menuData['MNU002'] ? menuData['MNU002'] : '',
	menuitem: 'MNU002',
	to: '/grand-lodge',
	divider: 'false',
	icon: 'fas fa-chess-rook-alt',
	children: [
		{ to: '/grand-lodge/grand-lodge', name: menuData['MNU002'] ? menuData['MNU002'] : '', divider: 'false' },
		{ to: '/grand-lodge/offices', name: menuData['MNU016'] ? menuData['MNU016'] : '', divider: 'false' }
	]
};

export const homeRoutes = {
	name: menuData['MNU001'] ? menuData['MNU001'] : '',
	to: '/',
	menuitem: 'MNU001',
	exact: true,
	divider: 'false',
	icon: 'fas fa-home'
};

export const recognitionRoutes = {
	name: menuData['MNU003'] ? menuData['MNU003'] : '',
	to: '/recognition',
	exact: true,
	divider: 'false',
	menuitem: 'MNU003',
	icon: 'fas fa-globe'
};

export const lodgeRoutes = {
	name: menuData['MNU004'] ? menuData['MNU004'] : '',
	to: '/lodges',
	menuitem: 'MNU004',
	exact: true,
	divider: 'false',
	icon: 'fas fa-map-marker-alt'
};

export const membersRoutes = {
	name: menuData['MNU005'] ? menuData['MNU005'] : '',
	to: '/members',
	menuitem: 'MNU005',
	exact: true,
	divider: 'false',
	icon: 'fas fa-users'
};

export const meetingdRoutes = {
	name: menuData['MNU015'] ? menuData['MNU015'] : '',
	to: '/meetings',
	menuitem: 'MNU',
	exact: true,
	divider: 'false',
	icon: 'fas fa-handshake'
};

export const conferencesRoutes = {
	name: menuData['MNU006'] ? menuData['MNU006'] : '',
	to: '/conferences',
	menuitem: 'MNU006',
	exact: true,
	divider: 'false',
	icon: 'fas fa-chart-network'
};

export const userPaymentsRoutes = {
	name: 'User Payments',
	to: '/user/payment',
	menuitem: 'MNU006',
	exact: true,
	divider: 'false',
	icon: 'fas fa-chart-network'
};

export const dataSharingRoutes = {
	name: menuData['MNU009'] ? menuData['MNU009'] : '',
	to: '/sharing',
	menuitem: 'MNU009',
	exact: true,
	divider: 'false',
	icon: 'fas fa-shield-alt'
};

export const statisticsRoutes = {
	name: menuData['MNU007'] ? menuData['MNU007'] : '',
	to: '/statistics',
	menuitem: 'MNU',
	exact: true,
	divider: 'false',
	icon: 'fas fa-analytics'
};

export const resourcesRoutes = {
	name: menuData['MNU010'] ? menuData['MNU010'] : '',
	to: '/resources',
	menuitem: 'MNU010',
	exact: true,
	divider: 'true',
	icon: 'fas fa-folder-open'
};

export const profileRoutes = {
	name: menuData['MNU013'] ? menuData['MNU013'] : '',
	to: '/profile',
	menuitem: 'MNU013',
	exact: true,
	divider: 'false',
	icon: 'fas fa-user-circle'
};

export const logoutRoutes = {
	name: menuData['MNU014'] ? menuData['MNU014'] : '',
	to: '/authentication/basic/logout',
	exact: true,
	menuitem: 'MNU014',
	divider: 'false',
	icon: 'fas fa-sign-out-alt'
};

export const PaymentRoutes = {
	name: menuData['MNU017'] ? menuData['MNU017'] : '',
	to: '/payments',
	exact: true,
	menuitem: 'MNU017',
	divider: 'false',
	icon: 'fas fa-globe'
};

export const rightSideMenuList = [
	{
		name: adminMenu['AMU001'] ? adminMenu['AMU001'] : '',
		to: '/pages/admin/cso',
		icon: 'fas fa-analytics',
		divider: false
	},
	{
		name: adminMenu['AMU002'] ? adminMenu['AMU002'] : '',
		to: '/pages/admin/cct',
		icon: 'fas fa-chart-line',
		divider: false
	},

	{
		name: adminMenu['AMU003'] ? adminMenu['AMU003'] : '',
		to: '/pages/admin/cmp',
		icon: 'fas fa-globe',
		divider: true
	},
	{
		name: adminMenu['AMU004'] ? adminMenu['AMU004'] : '',
		to: '/',
		icon: 'fas fa-chess-rook',
		divider: false
	},
	{
		name: adminMenu['AMU005'] ? adminMenu['AMU005'] : '',
		to: '/pages/admin/mstb',
		icon: 'fas fa-podcast',
		divider: true
	},
	{
		name: adminMenu['AMU006'] ? adminMenu['AMU006'] : '',
		to: '/pages/admin/gls',
		icon: 'fas fa-user-chart',
		divider: false
	},
	{
		name: adminMenu['AMU007'] ? adminMenu['AMU007'] : '',
		to: '/pages/admin/gla',
		icon: 'fas fa-apple-alt',
		divider: false
	},
	{
		name: adminMenu['AMU008'] ? adminMenu['AMU008'] : '',
		to: '/pages/admin/uv',
		icon: 'fas fa-question-circle',
		divider: false
	},
	{
		name: adminMenu['AMU009'] ? adminMenu['AMU009'] : '',
		to: '/pages/admin/abd',
		icon: 'fas fa-book-open',
		divider: true
	},
	{
		name: adminMenu['AMU010'] ? adminMenu['AMU010'] : '',
		to: '/pages/admin/gll',
		icon: 'fas fa-certificate',
		divider: false
	},
	{
		name: adminMenu['AMU017'] ? adminMenu['AMU017'] : '',
		to: '/pages/admin/profile/images',
		icon: 'fas fa-camera-retro',
		divider: true
	},
	{
		name: adminMenu['AMU011'] ? adminMenu['AMU011'] : '',
		to: '/pages/admin/actions',
		icon: 'fas fa-random',
		divider: false
	},
	{
		name: adminMenu['AMU012'] ? adminMenu['AMU012'] : '',
		to: '/pages/admin/translation',
		icon: 'fas fa-language',
		divider: false
	},
	{
		name: adminMenu['AMU013'] ? adminMenu['AMU013'] : '',
		to: '/',
		icon: 'fas fa-cloud-upload',
		divider: false
	},
	{
		name: adminMenu['AMU014'] ? adminMenu['AMU014'] : '',
		to: '/pages/admin/misc/items',
		icon: 'fas fa-biohazard',
		divider: true
	},

	{
		name: adminMenu['AMU018'] ? adminMenu['AMU018'] : '',
		to: '/grand-lodge/demographics',
		icon: 'fas fa-chess-rook-alt',
		divider: false
	},
	{
		name: adminMenu['AMU019'] ? adminMenu['AMU019'] : '',
		to: '/acacia/acacia',
		icon: 'fas fa-wheat',
		divider: false
	},
	{
		name: adminMenu['AMU020'] ? adminMenu['AMU020'] : '',
		to: '/acacia/readiness',
		icon: 'fas fa-tasks',
		divider: false
	},
	{
		name: adminMenu['AMU021'] ? adminMenu['AMU021'] : '',
		to: '/acacia/orders',
		icon: 'fas fa-shopping-cart',
		divider: false
	},
	{
		name: adminMenu['AMU022'] ? adminMenu['AMU022'] : '',
		to: '/documentation',
		icon: 'fas fa-book',
		divider: false
	},
	{
		name: adminMenu['AMU023'] ? adminMenu['AMU023'] : '',
		to: '/changelog',
		icon: 'fas fa-code-branch',
		divider: true
	},
	{
		name: adminMenu['AMU015'] ? adminMenu['AMU015'] : '',
		to: '/profile',
		icon: 'fas fa-user-circle',
		divider: false
	},
	{
		name: adminMenu['AMU024'] ? adminMenu['AMU024'] : '',
		to: '/pages/admin/lists',
		icon: 'fas fa-list',
		divider: false
	},
	{
		name: adminMenu['AMU025'] ? adminMenu['AMU025'] : '',
		to: '/pages/admin/remote/entities',
		icon: 'fas fa-project-diagram',
		divider: false
	},
	{
		name: adminMenu['AMU016'] ? adminMenu['AMU016'] : '',
		to: '/authentication/basic/logout',
		icon: 'fas fa-sign-out-alt',
		divider: false
	}
];

export default [
	homeRoutes,
	grandLodgeRoutes,
	recognitionRoutes,
	lodgeRoutes,
	membersRoutes,
	meetingdRoutes,
	PaymentRoutes,
	conferencesRoutes,
	statisticsRoutes,
	dataSharingRoutes,
	resourcesRoutes,
	profileRoutes,
	logoutRoutes
];
