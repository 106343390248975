/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, checkValidation } from '../../../api/api'; // eslint-disable-next-line
import config from '../../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import FormGroupInput from '../../../common/FormGroupInput';
// eslint-disable-next-line
import { Row, Col, Button } from 'reactstrap';
// eslint-disable-next-line
import CopiriLoader from '../../../common/CopiriLoader';
import image_loader from '../../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
const WebsiteField = props => {
	// eslint-disable-next-line
	const { lodgeData, id, closeModal, updateLodgeModal, pageLanguageContent } = props;
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		website: ''
	});
	const [validation, setValidation] = useState({
		error_url: ''
	});

	useEffect(() => {
		if (props.id) {
			const { website } = lodgeData;
			setInputField({ ...inputField, website });
		}
		// eslint-disable-next-line
	}, [props.id]);

	/* function to get the input values */
	const inputsHandler = e => {
		setCheckDataChange(true);
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};
	/* function to update the website filed on lodge modal */
	function updateData() {
		if (checkDataChange) {
			let errors = { ...validation };
			var errorCount = 0;
			var checkError = checkValidation(inputField['website'], 'url');
			if (!inputField.website.trim()) {
				errors.error_url = pageLanguageContent['CLD071'];
				errorCount++;
			} else if (!checkError) {
				errors.error_url = pageLanguageContent['CLD077'];
				errorCount++;
			} else {
				errors.error_url = '';
			}
			setValidation(errors);
			if (errorCount <= 0) {
				var sendFormData = {};
				var sendData = {};
				sendFormData['url'] = inputField['website'];
				sendData['value'] = sendFormData;
				setShowLoader(true);
				copiriPortalRequest('/organizations/' + lodgeData['id'] + '/website', config.app_version, 'patch', sendData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							closeModal();
							updateLodgeModal('website', inputField['website']);
						}
					})
					.catch(error => {
						setShowLoader(false);
					});
			}
		}
	}
	return (
		<Row>
			<Col lg={12}>
				<FormGroupInput
					id='website'
					label={pageLanguageContent['CLD034'] ? pageLanguageContent['CLD034'] : ''}
					placeholder=''
					type='text'
					name='website'
					onChange={inputsHandler}
					value={inputField.website}
				/>
				{validation.error_url && <p className='formValidationError'>{validation.error_url}</p>}
			</Col>
			<Col lg={12}>
				<Button
					color='primary'
					id={'confirmValidatebutton'}
					style={{ float: 'right', marginBottom: '15px' }}
					onClick={updateData}
				>
					{showLoader ? (
						<img src={image_loader} alt='loader' height='20' width='20' />
					) : pageLanguageContent['CLD124'] ? (
						pageLanguageContent['CLD124']
					) : (
						''
					)}
				</Button>
			</Col>
		</Row>
	);
};
export default WebsiteField;
